import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';

class ChangePassword
{
  form_validation()
  {
    var form_class           = ".ChangePasswordPage__Form";
    var matching_fields      = [{'original_field_id': '#new_password', 'field_to_be_matched_name':'confirm_password'}];
    var required_form_fields = ['old_password', 'new_password', 'confirm_password'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.fields_matches_validations(matching_fields);
    form_validation.formvalidate();
  }

  password_icon_click(element)
  {

    var password = new PasswordField();

    if( $(element).hasClass('fa-eye-slash') )
    {
        password.show_password(element);
    }
    else if($(element).hasClass('fa-eye'))
    {
        password.hide_password(element);
    }
  }

}

export default ChangePassword;
