import Settings from './settings';
import InputField from '../../common/inputfieldmax';

$(document).on('turbolinks:load',()=>
{
    var settings = new Settings();
    new InputField().maxlength($('.SettingPage__Form .Form__Input:first'),255);
    new InputField().maxlength($('.SettingPage__Form .Form__Input:last'),255);
    settings.form_validation();

    // ----------  On Confirm Deletion Click Show Modal -----------
        $('.SettingPage__DeleteAccount').click(function(){
            $('.ConfirmationModal').css('display','block');
        })
    // ------------------------------------------------------------

    // ----------  On Click Of Cancel Show Modal -----------
        $('.ConfirmationModal__Cancel').click(function(){
            $('.ConfirmationModal').css('display','none');
        })
    // ------------------------------------------------------------

})
