class StepProgress
{

  add_ready_status(element,step_number)
  {
      var step     = $(element).find('.StepProgress__Step'+step_number);
      var progress  = $(element).find('.StepProgress__Progress'+step_number);
      step.removeClass('Done');
      step.addClass('Ready');
      progress.removeClass('Ready');
      progress.removeClass('Done');
  }

  add_done_status(element,step_number)
  {
    for(let number = 1; number <= step_number; number++)
    {
      var step     = $(element).find('.StepProgress__Step'+number);
      var progress  = $(element).find('.StepProgress__Progress'+number);
      step.removeClass('Ready');
      step.addClass('Done');
      progress.removeClass('Ready');
      progress.addClass('Done');
    }
  }

  remove_all_status(element,step_number, total_steps)
  {
    for(let number = total_steps; number > step_number; number--)
    {
      var step      = $(element).find('.StepProgress__Step'+number);
      var progress  = $(element).find('.StepProgress__Progress'+number);
      progress.removeClass('Ready');
      progress.removeClass('Done');
      step.removeClass('Ready');
      step.removeClass('Done');
    }
  }
}

export default StepProgress;
