import ChangePassword from './changepassword';

$(document).on('turbolinks:load',()=>
{
    var changepassword = new ChangePassword();
    changepassword.form_validation();

    $('.ChangePasswordPage__Form .Form__PasswordIcon').click(function(){
                                                                          changepassword.password_icon_click(this);
                                                                        })
})
