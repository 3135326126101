import Checkout from './checkout';
global.Checkout = Checkout;
$(document).on('turbolinks:load',()=>
{
    var checkout = new Checkout();

    // Delivery Details Form Validation
      checkout.delivery_form_validation();
    // Delivery Details Form Validation

    // initialize stripe for the payment section
      checkout.initialize_stripe();
    // initialize stripe for the payment section

    // Deliver Here Button
        $('.CheckoutPage__DeliverHere ').click( function()
        {
              checkout.deliver_here(this);
        })
    // Deliver Here Button


    // Change Button
        $('.CheckoutPage__Change ').click( function()
        {
              checkout.change(this);
        })
    // Change Button


    // Radio Button Click
        $('.CheckoutPage .Radiobutton__Button').click( function()
        {
              var address_parent = $(this).parents('.CheckoutPage__AddressItem');  // find the selected parent
              $('.CheckoutPage__DeliverHere').hide();                              // hide all deliver here button
              address_parent.find('.CheckoutPage__DeliverHere').show();                  // show the selected element deliver here button


              var card_parent = $(this).parents('.PaymentPage__CardSection');  // find the selected parent
              $('.PaymentPage__Pay').hide();                      // hide all deliver here button
              card_parent.find('.PaymentPage__Pay').show();            // show the selected element deliver here button

        })
    // Radio Button Click


    // When the no tent card is selected open the payment section
        if($('.CheckoutPage__AddressSection, .CheckoutPage__MobileAddressSection').length == 0 )
        {
            $('.CheckoutPage__PaymentSection .Form').css('display', 'grid');
            $('.CheckoutPage__MobilePaymentSection .Form').css('display', 'grid');
            $('.CheckoutPage__CardListSection').css('display, block');
        }
    // When the no tent card is selected open the payment section
        checkout.select_first_address_by_default();
        checkout.set_max_value();
        checkout.quantity_change_event();
        checkout.decrease_quantity_click();
        checkout.increase_quantity_click();
        new CustomSelect2().initialize_search_select2();
        checkout.apply_coupon_form();
        checkout.remove_coupon_form();
})
