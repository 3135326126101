import Splide from '@splidejs/splide';
import FormValidation from '../../common/formvalidation';

class Dashboard
{

  constructor()
  {
    this.splide = null;
  }

  initialize_slide()
  {
    var product_slug = $('#selected_product_slug').val();
    var element = $('[data-product-slug='+product_slug+']')

    this.splide = new Splide('#splide', {
                                            perPage: this.get_per_page(),
                                            perMove: 1,
                                            start: element.index(),
                                            keyboard: false,
                                            breakpoints: {
                                              '1024': {
                                                perPage: this.get_per_page_for_tablet()
                                              },
                                              '767': {
                                                perPage: 1
                                              }
                                            }
                                          }).mount();
  }

  get_per_page() {
    var per_page = 3;
    if ($('#splide .splide__list li').length < 3) {
      per_page = $('#splide .splide__list li').length;
    }

    return per_page;
  }

  get_per_page_for_tablet() {
    var tab_per_page = 2;
    if ($(window.innerWidth >= 768) && $('#splide .splide__list li').length < 3) {
      tab_per_page = $('#splide .splide__list li').length

    }
    return tab_per_page;
  }

  splide_on_click_event()
  {
    var k_this = this;
    this.splide.on('active', function(event)
    {
        var element = $(event)[0].slide;
        k_this.change_dashboard_info(element);
    })
  }

  add_form_attr_submit_btn()
  {
      var form_id =  $('.Dashboard__Form').attr('id');
      $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').attr('form',form_id);
  }

  change_dashboard_info(element)
  {
    var url = window.location.href;
    if( url.includes('/products') && url.includes('/new-book') )
    {
      var product_slug = $(element).attr('data-product-slug');
      if(!window.location.href.includes(product_slug))
      {
        var url = window.location.href.split('/products/');
        window.location.href = url[0]+'/products/'+product_slug+'/new-book';
      }
    }
  }

  remove_select_from_slide_item()
  {
      $('.splide__slide').removeClass('is-active');
  }


  is_10_checkbox_selected(min_length, max_length)
  {
    var checked = $('.guest_pages:checked').length;
    return checked >= min_length && checked <=max_length;
  }

  is_all_checkbox_selected(all_checkbox_count)
  {
    var checked = $('.guest_pages:checked').length;
    return checked == all_checkbox_count;
  }

  none_checkbox_selected()
  {
    var checked = $('.guest_pages:checked').length;
    return checked == 0;
  }

  select_all_checkbox(elements)
  {
    elements.prop('checked', true);
  }

  unselect_all_checkbox(elements)
  {
    elements.prop('checked', false);
  }

  change_select_all_button_text(element, all_cards_count)
  {
    if(this.is_all_checkbox_selected(all_cards_count))
    {
      $(element).html('Unselect All');
    }
    else
    {
      $(element).html('Select All');
    }
  }

  change_button_text(element, text)
  {
    $(element).html(text);
  }

  update_selected_entries(selected_guests)
  {
    $('#selected-guests').text(selected_guests);
  }


  message_Form_Validation()
  {
    var form_class           = ".GuestBookPage__MessageForm";
    var required_form_fields = ['product_guest[name]','product_guest[message]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

  set_masonary_height()
  {
      $('.Dashboard__Form .fields').each(function()
      {
          var window_width    = window.screen.width;
          var viewport_height = (100/1440*26);
          var px_height       = (viewport_height*1440/100);
          var height          = parseInt(this.scrollHeight/px_height) + 1;
          $(this).css('grid-row', 'span'+' '+height);
      })
  }

  disabled_build_my_book_button()
  {
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').attr('disabled',true);
    $('.tooltiptext').css('display','block');
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('border', ' 1px solid rgba(253, 133, 106, 0.5)');
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('color', 'rgba(253, 133, 106, 0.5)');
  }

  enable_build_my_book_button()
  {
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').attr('disabled', false);
    $('.tooltiptext').css('display','none');
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('border', ' 1px solid #FD856A');
    $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('color', '#FD856A');
  }

}

export default Dashboard;
