class RecaptchaValidation
{
  imNotARobot()
  {
    var recaptcha = document.querySelectorAll('.g-recaptcha');

    if(recaptcha.length > 0 )
    {
      $(".recaptcha_error").html('');
      if (grecaptcha.getResponse().length !== 0)
      {
          return grecaptcha.getResponse().length !== 0;
      }
      else
      {
          return grecaptcha.getResponse(1).length !== 0;
      }
    }
  };

  submitInvisibleRecaptchaForm()
  {
    var recaptcha = document.querySelectorAll('.g-recaptcha');

    if(recaptcha.length > 0 )
    {
  		$(".recaptcha_error").html('');
  		if (grecaptcha.getResponse().length !== 0)
  		{
  				return grecaptcha.getResponse().length !== 0;
  		}
  		else
  		{
  				return grecaptcha.getResponse(1).length !== 0;
  		}
    }
  };

}

export default RecaptchaValidation;
