$(document).on('turbolinks:load',()=>
{
    $(window).click(function(event)
    {
        if($('.Shipped-Info-Popup').css('display') != 'none' && !event.target.classList.contains('My-Order__Card-Header-Owner-Name'))
        {

            $('.Shipped-Info-Popup').css('display','none');
        }// $(this).find('.Shipped-Info-Popup').css('display);
    })

    $('.My-Order__Card-Header-Owner-Name').click(function()
    {
       if($(this).find('.Shipped-Info-Popup').css('display') == 'none')
       {
         $('.Shipped-Info-Popup').css('display','none');
         $(this).find('.Shipped-Info-Popup').css('display','block');
       }
       else
       {
         $('.Shipped-Info-Popup').css('display','none');
       }
    })

})
