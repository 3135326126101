import GetEmailAddress from './get_email_address';

$(document).on('turbolinks:load',()=>
{
  var get_email_address = new GetEmailAddress();
  get_email_address.form_validation();
  get_email_address.password_icon_click();


  $('.Link_Your_Account').click(function()
  {
    get_email_address.ToggleExistingUser()
  })

  $('.Update_Email_Address').click(function()
  {
    get_email_address.ToggleExistingUser()
  })
})
