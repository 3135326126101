import CustomPlyr from '../../components/customplyr';
import GuestGallery from './guest_gallery_page';

$(document).on('turbolinks:load', () => {

  const guest_gallery = new GuestGallery();
  guest_gallery.form_validation();

  $('#GuestBookShareMediaPage__ShareMedia').click(function()
  {
    $('#product_gallery_media').trigger('click');
  })

  $('#product_gallery_media').change(function() {
    var url   = null;
    var files = this.files;
    // $('.GuestBookShareMediaPage__UploadedMediaSection').html('');
    guest_gallery.append_files(files);
    guest_gallery.delete_media_event();
    guest_gallery.play_video_media_gallery_event();
    guest_gallery.close_modal_event();
    $('.GuestBookShareMediaPage__Step2').show();
  })

  $('.GuestBookShareMediaPage__NextButton').click(function() {
      guest_gallery.next_section();
      // $('.GuestBookShareMediaPage__Form-Section').css('padding','40px 15px');
  })

  $('.GuestGalleryAdditionDetails__Submit').click(function() {
    $('.GuestGalleryAdditionDetails__Submit').attr('disabled',true);
    guest_gallery.submit_form();
  })

})
