import FormValidation from '../../common/formvalidation';
import CustomPlyr from '../../components/customplyr';
import Loader from '../../components/loader';

class GuestGallery {
  constructor() {
    this.custom_plyr = new CustomPlyr();
    this.files_list = []
    this.remove_media_indexes = [];
  }

  form_validation() {
    var form_class = "#GuestBookGalleryPage__AdditionalDetailsForm";
    var required_form_fields = ['product_gallery[name]', 'product_gallery[email]'];
    var form_validation = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

  remove_media(current_element) {
    var element = $(current_element).parents('.GuestBookShareMediaPage__MediaSection')
    var index = $(element).attr('data-media-id');
    this.remove_media_indexes.push(index);
    element.remove();

    if ($('.GuestBookShareMediaPage__UploadedMediaSection').children().length == 0) {
      $('.GuestBookShareMediaPage__Step2').hide();
    }
  }

  append_files(files)
  {
      const KB_IN_GB = 1048576000;
      for (let index = 0; index < files.length; index++)
      {
          console.log(this.calculate_file_size_in_gb(this.files_list));
          if(this.calculate_file_size_in_gb(this.files_list)/KB_IN_GB  <  1)
          {
            this.files_list.push(files[index])
            var media_index = 0;
            if(this.files_list.length>1)
            {
              media_index = this.files_list.length;
            }
            var url = window.URL.createObjectURL(files[index]);
            if (files[index].type.includes("image")) {
              this.append_image_in_media(url, media_index);
            } else if (files[index].type.includes("video")) {
              this.append_video_in_media(url, media_index);
            }
          }
          else
          {
            Toastr.success("You have reached to you maximum limit");
            break;
          }
      }
  }

  append_image_in_media(url, media_id) {
    $('.GuestBookShareMediaPage__UploadedMediaSection').append(`<div class="GuestBookShareMediaPage__MediaSection" data-media-id='${media_id}'>
                                                                          <img class="GuestBookShareMediaPage__Image" src='${url}'/>
                                                                          <div   class="GuestBookShareMediaPage__CloseSection">
                                                                              <i class="fal fa-times GuestBookShareMediaPage__Close"></i>
                                                                          </div>
                                                                </div>
                                                              `);
  }

  append_video_in_media(url, media_id) {
    $('.GuestBookShareMediaPage__UploadedMediaSection').append(`<div class="GuestBookShareMediaPage__MediaSection" data-media-id='${media_id}'>
                                                                          <video class="GuestBookShareMediaPage__Video" src='${url}'></video>
                                                                          <div   class="GuestBookShareMediaPage__CloseSection">
                                                                              <i class="fal fa-times GuestBookShareMediaPage__Close"></i>
                                                                          </div>
                                                                          <div class="GuestBookShareMediaPage__PlaySection">
                                                                              <i class="fas fa-play-circle GuestBookShareMediaPage__Play"></i>
                                                                          </div>
                                                                      </div>
                                                                    `);
  }

  next_section() {
    $('.GuestBookShareMediaPage__Step1').hide();
    $('.GuestBookShareMediaPage__Step2').hide();
    $('.GuestBookShareMediaPage__Heading').hide();
    $('.GuestBookShareMediaPage__Step3').show();
    $('.GuestBookShareMediaPage__Form-Section').addClass('Additional__Form');
    // $('.GuestBookShareMediaPage__Form-Section').css('max-width','460px');
    // $('.GuestBookShareMediaPage__Form-Section').css('width','70%');
  }

  delete_media_event() {

    var k_this = this;
    $('.GuestBookShareMediaPage__CloseSection').click(function(event) {
      var current_element = event.target;
      k_this.remove_media(current_element);
    })

  }

  play_video_media_gallery_event() {
    var k_this = this;
    // ------- Play Video Modal-------
    $('.GuestBookShareMediaPage__Play').click(function(event) {
      var video_src = $(event.target).parents('.GuestBookShareMediaPage__MediaSection').find('video').attr('src');
      k_this.custom_plyr.standard_initialize();
      k_this.custom_plyr.set_video_source(video_src);
      k_this.custom_plyr.show_standard_video_modal();
    })
    // ------- Play Video Modal-------
  }

  close_modal_event() {
    var k_this = this;

    // ------- Close Video Modal-------
    $('.GuestBookShareMediaPage').siblings('.Video__Modal').find('.Modal__CloseIcon').click(function() {
      k_this.custom_plyr.destroy_video_modal();
    })
    // ------- Close Video Modal-------
  }

  calculate_file_size_in_gb(file_array)
  {
      var size = 0;
      for(var file of file_array)
      {
        size = size+file.size;
      }
      return size;
  }

  submit_form()
  {
    var name            = $('#product_gallery_name').val();
    var email           = $('#product_gallery_email').val();
    var slug            = $('#slug').val();
    var files_array     = Array.from(this.files_list);
    var k_this          = this;
    var url             = $('#GuestBookGalleryPage__AdditionalDetailsForm').attr('action');
    for(let index in files_array)
    {
          if( this.remove_media_indexes.includes(index) )
          {
            files_array.splice(index,1);
          }
    }

    var formData = new FormData();  //Why we need this
    formData.append('product_gallery[name]',name);
    formData.append('product_gallery[email]',email);
    for(let index in files_array)
    {
      formData.append('product_gallery[media][]', files_array[index]);
    }

    if($('#GuestBookGalleryPage__AdditionalDetailsForm').valid())
    {
      new Loader().show('Video');
      $.ajax(
              {
                url: url,
                method:'post',
                processData: false,  //Why we need this
                contentType: false,  //Why we need this
                data: formData,
                success: (results) => {
                                        $('.GuestGalleryAdditionDetails__Submit').attr('disabled',false);
                                      }
              }
            )
    }
    else
    {
      $('.GuestGalleryAdditionDetails__Submit').attr('disabled',false);
    }
  }

}

export default GuestGallery;
