class CustomValidators
{

  is_value_equal_to_current_date()
  {
    $.validator.addMethod("is_value_equal_to_current_date", function(value, element)
    {
        var date_format = /^\d{2}-\d{2}-\d{4}$/;
        if(value.match(date_format))
        {
            var date_array = value.split('-');
            var input_value  = new Date(date_array[0], date_array[1], date_array[2]);
            var current_date = new Date();
            return current_date >= input_value;
        }
        else
        {
          return false;
        }
    });
  }

  custom_checkbox_validation()
  {
    $.validator.addMethod("custom_checkbox_validation", function(value, element)
    {
    	if( $('#filters_order_placed').prop('checked') || $('#filters_shipped').prop('checked') || $('#filters_cancelled').prop('checked'))
    	{
    			$('#filters_order_placed').parent().find('.checkmark').css("border-color",'#A08AC4');
    			$('#filters_shipped').parent().find('.checkmark').css("border-color",'#A08AC4');
    			$('#filters_cancelled').parent().find('.checkmark').css("border-color",'#A08AC4');
    			return true;
    	}
    	else
    	{
    		$('#filters_order_placed').parent().find('.checkmark').css("border-color",'red');
    		$('#filters_shipped').parent().find('.checkmark').css("border-color",'red');
    		$('#filters_cancelled').parent().find('.checkmark').css("border-color",'red');
    		return false;
    	}
    });
  }

  customise_book_input_type_file_validation()
  {
    $.validator.addMethod("Book-Template1__SelectImage", function(value, element)
    {
        if(
           !( $('.Book-Template1__SelectImage').val()=='' &&
               $('.Book-Template1__Image').css('background-image').includes('/assets/Guest/Group.png')
            ))
        {
          return true;
        }
        else
        {
          return false;
        }
    })
  }

}

export default CustomValidators;
