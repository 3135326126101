class Dropdown
{

  openDropdown(clickElement)
  {
    $(clickElement).parents('.DropdownSection').find('.Dropdown__ListSection').css('display','block');
  }

  openNestedDropdown(clickElement)
  {
    $(clickElement).find('.nestedDropdown__ListSection').css('display','block');
  }

  change_list_close_icon_to_list_open_icon(clickElement)
  {
     var element = $(clickElement).find('.fa-caret-down');
     element.removeClass('fa-caret-down');
     element.addClass('fa-caret-up');
  }

  nestedDropdown_change_list_close_icon_to_list_open_icon(clickElement)
  {
     $(clickElement).removeClass('Close');
     $(clickElement).addClass('Open');
  }

  change_all_list_open_icon_to_list_close_icon()
  {
    var element = $('.Dropdown').find('.fa-caret-up');
    element.removeClass('fa-caret-up');
    element.addClass('fa-caret-down');
  }

  nestedDropdown_change_all_list_open_icon_to_list_close_icon()
  {
      var element = $('.nestedDropdown');
      $(element).removeClass('Open');
      $(element).addClass('Close');
  }

  closeAllDropdown()
  {
    $(".Dropdown__ListSection").fadeOut();
  }

  closeAllNestedDropdown()
  {
      $(".nestedDropdown__ListSection").fadeOut();
  }

  closenestedDropdown(clickElement)
  {
    $(clickElement).find('.nestedDropdown__ListSection').css('display','none');
  }
}

export default Dropdown;
