import Splide from '@splidejs/splide';

class ChooseTemplate
{

    constructor() {
      this.splide = null;
    }

    initialize_splide()
    {
      this.splide = new Splide('#choose-template__how-it-works', {
        type: 'loop'
      }).mount();

      $('.SelectTemplatePage__SlideSection').each(function() {
        var splide = new Splide(this, {
          type: 'loop',
          pagination: true,
          arrows: false,
        }).mount();

        // Start autoplay on hover and stop when not hovering
        // Variables to hold interval IDs
        var autoAdvanceInterval;

        // Function to start auto-advance
        function startAutoAdvance() {
            autoAdvanceInterval = setInterval(function() {
                splide.go('>');
            }, 2000); // Adjust this interval as needed
        }

        // Function to stop auto-advance
        function stopAutoAdvance() {
            clearInterval(autoAdvanceInterval);
        }

        // Attach hover event handlers
        $(this).hover(startAutoAdvance, stopAutoAdvance);

        // Move the carousel in the respective directions on clicking left or right sides
        $(this).on('click', function(e) {
          var carouselWidth = $(this).width();
          var clickPosition = e.pageX - $(this).offset().left;

          if (clickPosition < carouselWidth / 2) {
              splide.go('<');
          } else {
              splide.go('>');
          }
        });
      });
    }

    scroll_to_section1()
    {
      $('html, body').animate({
                                scrollTop: $(".ChooseTemplatePage__Section1").offset().top
                             });
    }


}

export default ChooseTemplate;
