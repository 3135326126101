import WindowSize from '../../common/windowsize';
import Dropdown from '../../components/dropdown';

class Header
{

  constructor()
  {
    this.windowSize = new WindowSize();
  }

  dropdown()
  {
    $('.Dropdown').click( (element) => {
                                          let dropdown = new Dropdown();
                                          let clickElement = element.currentTarget;

                                          if( $(clickElement).parents('.DropdownSection').find('.Dropdown__ListSection').is(':hidden') )
                                          {
                                              dropdown.closeAllNestedDropdown();
                                              dropdown.nestedDropdown_change_all_list_open_icon_to_list_close_icon();
                                              dropdown.closeAllDropdown();
                                              dropdown.change_all_list_open_icon_to_list_close_icon();
                                              dropdown.openDropdown(clickElement);
                                              dropdown.change_list_close_icon_to_list_open_icon(clickElement);
                                          }
                                          else
                                          {
                                              dropdown.closeAllDropdown();
                                              dropdown.change_all_list_open_icon_to_list_close_icon();
                                          }
                                       })
  }


  nestedDropdown()
  {
    $('.nestedDropdown').click( (element) => {
                                                let dropdown = new Dropdown();
                                                let clickElement = element.currentTarget;

                                                if( $(clickElement).find('.nestedDropdown__ListSection').is(':hidden') )
                                                {
                                                    dropdown.openNestedDropdown(clickElement);
                                                    dropdown.nestedDropdown_change_list_close_icon_to_list_open_icon(clickElement);
                                                }
                                                else
                                                {
                                                    dropdown.closenestedDropdown(clickElement);
                                                    dropdown.nestedDropdown_change_all_list_open_icon_to_list_close_icon();
                                                }

                                             })
  }


  show_hide_header()
  {
      if(this.windowSize.is_mobile())
      {
        $('.MobileHeader').show();
        $('.Header-Section').hide();
      }
      else
      {
        $('.Header-Section').show();
        $('.MobileHeader').hide();
      }
  }


  add_dropdown_in_MyAccount()
  {
    var element           = $('.Dropdown__ListItem.MyAccount').find('a');
    var element_to_append = $('.MyAccount__Profile-ListSection');
    $(element_to_append).insertAfter(element);
  }

  closeDropdowns(event)
  {
      let dropdown = new Dropdown();
      var parent_for_dropdown_list = $(event.target).parents('.DropdownSection');
      var parent_for_nested_list = $(event.target).parents('.nestedDropdown');

      if( parent_for_dropdown_list.length == 0 && parent_for_nested_list.length ==0)
      {

        dropdown.closeAllDropdown();
        dropdown.closeAllNestedDropdown();
        dropdown.change_all_list_open_icon_to_list_close_icon();
      }
  }

}

export default Header;
