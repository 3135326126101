import textfit from 'textfit/textFit.js';
import flowtype from '../../common/flowtype.js';

class Preview
{
    next_page()
    {
        var show_page_heading = $('.PreviewBook__CardPageSection').not('.d-none').find('.PreviewBook__PageInfo').text().trim();
        var last_page_number  = $('.PreviewBook__CardPageSection').last().find('.PreviewBook__PageInfo p').text().trim();
        last_page_number      = parseInt(last_page_number.split(' ')[1]);
        this.next_icons(show_page_heading, last_page_number);
        var page_number       = parseInt(show_page_heading.split(' ')[1]);
        this.show_hide_page(show_page_heading, page_number+1);
        this.set_font_sizes();
    }

    previous_page()
    {
        var show_page_heading = $('.PreviewBook__CardPageSection').not('.d-none').find('.PreviewBook__PageInfo').text().trim();
        this.previous_icons(show_page_heading);
        var page_number = parseInt(show_page_heading.split(' ')[1]);
        this.show_hide_page(show_page_heading, page_number-1);
        this.set_font_sizes();
    }

    next_icons(show_page_heading, page_number)
    {
      if(show_page_heading=="Page "+(page_number-1))
      {
         this.hide_next_icon();
      }
      else
      {
          this.show_next_icon();
          this.show_previous_icon();
      }
    }

    previous_icons(show_page_heading)
    {
      if(show_page_heading == "Page 1")
      {
        this.hide_previous_icon();
      }
      else
      {
          this.show_previous_icon();
          this.show_next_icon();
      }
    }

    show_hide_page(show_page_heading, page_number)
    {
        if(show_page_heading=="Cover Page")
        {
            show_page_heading = "Page 1";
        }
        else if( page_number == 0)
        {
            show_page_heading = "Cover Page";
        }
        else
        {
            var count = parseInt(show_page_heading.split(" ")[1]);
            show_page_heading = "Page "+ (page_number);
        }
        var page = this.find_element_by_heading(show_page_heading);
        $('.PreviewBook__CardPageSection').addClass('d-none');
        $(page).removeClass('d-none');
    }

    find_element_by_heading(text)
    {
      return $('.PreviewBook__PageInfo p:contains("'+text+'")').first().parents('.PreviewBook__CardPageSection');
    }

    hide_next_icon()
    {
      // $('.PreviewBook__Next').hide();
      $('.PreviewBook__Next').css('pointer-events', 'none');
      $('.PreviewBook__Next').css('color', '#808080');
    }

    show_next_icon()
    {
      // $('.PreviewBook__Next').show();
      $('.PreviewBook__Next').css('pointer-events', 'auto');
      $('.PreviewBook__Next').css('color', '#FD856A');
    }

    hide_previous_icon()
    {
      // $('.PreviewBook__Previous').hide();
      $('.PreviewBook__Previous').css('pointer-events', 'none');
      $('.PreviewBook__Previous').css('color', '#808080');
    }

    show_previous_icon()
    {
      // $('.PreviewBook__Previous').show();
      $('.PreviewBook__Previous').css('pointer-events', 'auto');
      $('.PreviewBook__Previous').css('color', '#FD856A');

    }

    set_cover_font_sizes()
    {
      // textfit( $('.Book-Template1__Input1'), {minFontSize: 10, maxFontSize:18, multiLine: false});
      // textfit( $('.Book-Template1__Input2'), {minFontSize: 10, maxFontSize:18, multiLine: false});

      // textfit( $('.Book-Template2__Input1'), {minFontSize: 8, maxFontSize:36, multiLine: false});
      // textfit( $('.Book-Template2__Input2'), {minFontSize: 8, maxFontSize:20, multiLine: false});

      // textfit( $('.Book-Template3__Input1'), {minFontSize: 8, maxFontSize:30, multiLine: false});
      // textfit( $('.Book-Template3__Input2'), {minFontSize: 8, maxFontSize:50, multiLine: false});
      // textfit( $('.Book-Template3__Input3'), {minFontSize: 8, maxFontSize:30, multiLine: false});
    }

    set_font_sizes()
    {
      // textfit( $('.CreateBookCard__Input'), {minFontSize:8, maxFontSize: 15, multiLine: true});
      // textfit( $('.PreviewBook__Page .CreateBookCard__Input-square-birthday'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
      // textfit( $('.PreviewBook__Page .CreateBookCard__SenderName-square-birthday'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
      // textfit( $('.PreviewBook__Page .CreateBookCard__Input-round-baby'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
      // textfit( $('.PreviewBook__Page .CreateBookCard__SenderName-round-baby'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
    }
}

export default Preview;
