import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';

class ResetPassword
{

  form_validation()
  {
    var form_class           = ".ResetPasswordPage__Form";
    var required_form_fields = ['user[password]','user[password_confirmation]'];
    var match_form_fields    = [{'original_field_id': '#user_password', 'field_to_be_matched_name':'user[password_confirmation]'}];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.fields_matches_validations(match_form_fields);
    form_validation.formvalidate();
  }

  password_icon_click()
  {
    var password = new PasswordField();
    $('.ResetPasswordPage__PasswordSection .Form__PasswordIcon').click(function(){
                                                                            if( $(this).hasClass('fa-eye-slash') )
                                                                            {
                                                                                password.show_password(this);
                                                                            }
                                                                            else if($(this).hasClass('fa-eye'))
                                                                            {
                                                                                password.hide_password(this);
                                                                            }
                                                                         })
  }

}

export default ResetPassword;
