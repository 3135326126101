import CustomizeTentCard from './customize_tent_card';
import InputField from '../../common/inputfieldmax';

$(document).on('turbolinks:load',()=>
{

    var customize_tent_card = new CustomizeTentCard();
    new InputField().maxlength($('.TentCustomisationPage__Input'),30);
    var tent_card_form        = $('.Tent-Card__Form');

    if(tent_card_form.length > 0)
    {
      var is_form_validate = $('.Tent-Card__Form').valid();
      customize_tent_card.set_submit_button(is_form_validate);
    }

    // var input_element = $('.TentCustomisationPage__FontColorRadioButton:checked');
    // customize_tent_card.change_tent_card_input_background_color(input_element);

    $('.TentCustomisationPage__ThemeRadioButton').on('change', function()
    {
      customize_tent_card.change_tent_card_background_color(this);
    })

    $('.TentCustomisationPage__FontColorRadioButton').on('change', function()
    {
        customize_tent_card.change_tent_card_font_color(this);
        // customize_tent_card.change_tent_card_input_background_color(this);
    })

    $('.TentCustomisationPage__Input').keyup(function()
    {
        is_form_validate = $('.Tent-Card__Form').valid();
        customize_tent_card.set_submit_button(is_form_validate);
    })

    $('.TentCustomisationPage__Input').focus(function()
    {
        var input_element = $('.TentCustomisationPage__FontColorRadioButton:checked');
        customize_tent_card.change_tent_card_input_background_color(input_element);
    })

    $('.TentCustomisationPage__Input').blur(function()
    {
        $(this).css('background-color','transparent');
        $(this).css('border', '0px');
    })

    $('.TentCustomisationPage__Input').focus();

    customize_tent_card.default_select();

})

$(window).on('load', function()
{
  $('.TentCustomisationPage__Input').focus();
})
