import FormValidation from '../../common/formvalidation';

class AdditionalDetails
{

  form_validation()
  {
    var form_class           = "#GuestBookEntryPage__AdditionalDetails";
    var required_form_fields = ['product_guest[name]','product_guest[email]','product_guest[message]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }


}

export default AdditionalDetails;
