import OrderStatus from './orderstatus';

$(document).on('turbolinks:load',()=>
{

    var orderstatus = new OrderStatus();
    orderstatus.form_validation();
    orderstatus.apply_coupon_form();
    orderstatus.remove_coupon_form();
})
