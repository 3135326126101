import ChooseTemplate from './choosetemplate';

$(document).on('turbolinks:load',()=>
{
    var choosetemplate = new ChooseTemplate();

    if (window.location.href.includes('/choose-template'))
    {
      choosetemplate.initialize_splide();
    }

    $('.ChooseTemplatePage__Section2-Btn').click(function()
    {
        choosetemplate.scroll_to_section1();
    })


    /* Create New Modal Start */

      $("#Birthday").click(function() {
        $("#Birthday").css("border-width", "2px");
        $("#Anniversary").css("border-width", "1px");
        $("#Baby").css("border-width", "1px");
        $("#Wedding").css("border-width", "1px");

        $("#Birthday").css("margin-top", "20px");
        $("#Anniversary").css("margin-top", "0px");
        $("#Baby").css("margin-top", "0px");
        $("#Wedding").css("margin-top", "0px");
      });

      $("#Anniversary").click(function() {
        $("#Birthday").css("border-width", "1px");
        $("#Anniversary").css("border-width", "2px");
        $("#Baby").css("border-width", "1px");
        $("#Wedding").css("border-width", "1px");

        $("#Birthday").css("margin-top", "0px");
        $("#Anniversary").css("margin-top", "20px");
        $("#Baby").css("margin-top", "0px");
        $("#Wedding").css("margin-top", "0px");
      });

      $("#Baby").click(function() {
        $("#Birthday").css("border-width", "1px");
        $("#Birthday").css("margin-top", "20px");
        $("#Anniversary").css("border-width", "1px");
        $("#Baby").css("border-width", "2px");
        $("#Wedding").css("border-width", "1px");

        $("#Birthday").css("margin-top", "0px");
        $("#Anniversary").css("margin-top", "0px");
        $("#Baby").css("margin-top", "20px");
        $("#Wedding").css("margin-top", "0px");
      });

      $("#Wedding").click(function() {
        $("#Birthday").css("border-width", "1px");
        $("#Birthday").css("margin-top", "20px");
        $("#Anniversary").css("border-width", "1px");
        $("#Baby").css("border-width", "1px");
        $("#Wedding").css("border-width", "2px");

        $("#Birthday").css("margin-top", "0px");
        $("#Anniversary").css("margin-top", "0px");
        $("#Baby").css("margin-top", "0px");
        $("#Wedding").css("margin-top", "20px");
      });

    /* Create New Modal End */

})
