// import Constants from './constants';
// import PageLoader from './pageloader';
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';

class CameraFunctionality {

  constructor(video_object)
  {
    this.image_blob = null;
    this.videofunc  = video_object;
    this.player;
    this.permission;

  }

  initializeVideoScreen()
  {
    var k_this = this;

    if(navigator.userAgent.indexOf("Firefox") != -1 )
    {
      var IMAGE_WIDTH = 640;
      var IMAGE_HEIGHT = 480;
    }
    else
    {
      var IMAGE_WIDTH = 460;
      var IMAGE_HEIGHT = 460;
    }

    // ---------------------------

      this.videofunc.recordingBegins_Step_1(this);


    const options = {
    controls: false,
    fluid: true,
    bigPlayButton: false,
    controlBar: {
                    volumePanel: false,
                    fullscreenToggle: false
                },
    // dimensions of the video.js player
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    aspectRatio : "1:1",
    plugins: {
              record: {
                  debug: false,
                  imageOutputType: 'blob',
                  image: {
                    // image media constraints: set resolution of camera
                    width: { min: 460, ideal: 460, max: 1200},
                    height: { min: 460, ideal: 460, max: 1200 }
                  },
                  // // dimensions of captured video frames
                  frameWidth: 460,
                  frameHeight: 460
              }
    }

};

    this.player = videojs('CaptureImagePage__Preview', options, function()
                {
                    // print version information at startup
                    var msg = 'Using video.js ' + videojs.VERSION +
                              ' with videojs-record ' + videojs.getPluginVersion('record');
                    videojs.log(msg);
                });

    // error handling
    this.player.on('deviceError', function(errors)
    {
        console.warn('device error:', k_this.player.deviceErrorCode);
        alert("User doesn't allow the browser to access the webcam");
        location.reload();
    });


    this.player.record().getDevice();
    this.player.on('deviceReady', () =>
    {
        var countDownNumber = 5;
        var k_this = this;
        var counter =  setInterval(()=>{
                                               countDownNumber = countDownNumber -1;

                                               if(countDownNumber <= 5 && countDownNumber!==0)
                                               {
                                                   k_this.embeddedCountDownInPage(countDownNumber);
                                                   $('.CaptureImagePage__LoadingDots:nth-child('+(countDownNumber+1)+')').addClass('inactive');
                                               }

                                               if(countDownNumber == 0)
                                               {
                                                 clearInterval(counter);
                                                 k_this.getScreenShot();
                                                 k_this.onCountDownEndForCaptureImage();
                                               }
                                         },1000);
        $('.GuestBookEntryPage__Step1').hide();
        $('.GuestBookEntryPage__Step2').show();

    });

    this.player.on('error', function(element, error)
    {
        console.error(error);
        alert("Your browser is not updated fullfill the requirements");
        location.reload();
    });

    // snapshot is available
    this.player.on('finishRecord', function()
    {
        // the blob object contains the image data that
        // can be downloaded by the user, stored on server etc.
        k_this.image_blob = k_this.player.recordedData;
        k_this.player.record().stopStream();
        k_this.player.record().stopDevice();
        // k_this.image_blob = this.player.recordCanvas;
    });
  }

  getScreenShot()
  {
      this.player.record().start();
  }

  embeddedCountDownInPage(number)
  {
    $('.CaptureImagePage__CountDown, .GuestBookEntryPage__Step2-1 .CaptureImagePage__CountDown').text(number);
  }

  embeddedCountDownInVideoPage(text)
  {
    $('.CaptureImagePage__CountDown').text(text);
    $('.CaptureImagePage__CountDown').addClass('Start');
  }

  onCountDownEndForCaptureImage()
  {
      // $('.CaptureImagePage__Preview-Heading').html('Nailed it. Now stay put! <i class="fal fa-check"></i>');
      // $('.CaptureImagePage__Recording-Text').css('display','block');
      // $('#CaptureImagePage__Preview').hide();
      // $('#CaptureImagePage__Canvas').show();
      $('.GuestBookEntryPage__Step2').hide();
      $('.GuestBookEntryPage__Step3').show();
      $('.GuestBookEntryPage__Step3 .CaptureImagePage__LoadingDots').removeClass('inactive');

      // this.embeddedCountDownInPage(8);
      this.embeddedCountDownInVideoPage('Get ready to record your video');
      this.videoRecordingStartCountDown(8);
  }

  videoRecordingStartCountDown(countDownNumber)
  {
     var k_this = this;
     this.videofunc.player.record().getDevice();
     $('.Timescreen__CountDown').css('display','grid');
     $('#VideoPage__Preview').css('display','none');
     var counter = setInterval(()=>{
                                      countDownNumber = countDownNumber -1;

                                      if(countDownNumber <= 5 && countDownNumber!==0)
                                      {
                                          $('.CaptureImagePage__Preview-Heading').text('Record Your Video');
                                          $('.CaptureImagePage__CountDown, .GuestBookEntryPage__Step3 .CaptureImagePage__CountDown-Text').removeClass('Waiting_Message');
                                          $('#VideoPage__Preview').css('display','block');
                                          $('.CaptureImagePage__CountDown-Unit').removeClass('d-none');
                                          k_this.embeddedCountDownInPage(countDownNumber);
                                          $('.GuestBookEntryPage__Step3 .CaptureImagePage__LoadingDots:nth-child('+(countDownNumber+1)+')').addClass('inactive');
                                          $('.CaptureImagePage__CountDown').removeClass('Start');
                                      }

                                      if(countDownNumber == 0)
                                      {
                                        clearInterval(counter);
                                        k_this.onCountDownEndForRecordingVideos();
                                      }
                                    },1000)
  }

  onCountDownEndForRecordingVideos()
  {
    $('.GuestBookEntryPage__Step3 .CaptureImagePage__Preview-Heading').text('Recording...');
    this.videofunc.start_recording();
  }

}

export default CameraFunctionality;