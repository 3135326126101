import Splide from '@splidejs/splide';

class HomePage
{
    constructor() {
      this.splide = null;
    }

    initialize_splide()
    {
      this.splide = new Splide('#how-it-works', {
        type: 'loop'
      }).mount();
    }

    scroll_to_section3()
    {
      $('html, body').animate({
                                scrollTop: $(".HomePage__Section3").offset().top
                             });
    }

    scroll_to_section8()
    {
      $('html, body').animate({
                                scrollTop: $(".HomePage__Section8").offset().top
                             });
    }


}

export default HomePage;
