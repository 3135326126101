class WindowSize
{

  constructor()
  {
    this.width = $(window).outerWidth();
  }

  is_mobile()
  {
    return this.width < 768;
  }

  is_tablet()
  {
    return this.width > 768 && this.width < 1024;
  }

  is_desktop()
  {
    return this.width > 1024;
  }
}

export default WindowSize;
