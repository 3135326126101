class InputField
{

  maxlength(element,length)
  {
      $(element).on('keydown', function (event)
      {
          var value = element.val().substr(0, length);
          element.val(value);
      })
  }

}

export default InputField;
