import FormValidation from '../../common/formvalidation';
import CustomValidators from '../../common/custom_validation';

class Admin
{
    constructor()
    {
      var admin_table = "";
      var data_table_params = null;
    }

    open_the_filter()
    {
        $('.Filters__Form').css('display','grid');
    }

    close_the_filter()
    {
        $('.Filters__Form').css('display','none');
    }

    change_to_close_icon()
    {
        var element = $('.Filters__Icon').find('i')
        element.removeClass('fa-caret-up');
        element.addClass('fa-caret-down');
    }

    change_to_open_icon()
    {
      var element = $('.Filters__Icon').find('i')
      element.removeClass('fa-caret-down');
      element.addClass('fa-caret-up');
    }

    initialize_admin_table(filters = null)
    {
      var k_this = this;

      Admin_Table = $('.admin-table').DataTable(
      {
        "language":
        {
          searchPlaceholder: "Search",
          sProcessing: '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>',
          search: ''
        },
        "bSort": true,
        "responsive": true,
        "processing": true,
        "serverSide": true,
        "info": false,
        "searching": true,
        "pagingType": "simple_numbers",
        "order": [[ 3, "desc" ]],
        "lengthMenu": [10, 25, 50, 100],
        "columnDefs": [
                        { "width": "150px", "targets": 2 },
                        { "width": "150px", "targets": 6 }
                      ],
        "drawCallback": function( settings ) {
                                                new CustomSelect2().initialize_select2();
                                                k_this.open_modal();
                                                k_this.order_status_change();

                                              },
        "initComplete": function( settings, json ) {
                                                      k_this.data_table_params = $.param($('.admin-table').DataTable().ajax.params());
                                                      k_this.change_url("admin", '/admin?'+ k_this.data_table_params);
                                                      if($('.Export-Btn').length == 0)
                                                      {
                                                        $(".dataTables_filter label").after('<a class = "Export-Btn" target="blank" href="/all_orders_details.csv?'+k_this.data_table_params+'">Export</a>');
                                                      }
                                                      k_this.open_modal();
                                                      k_this.order_status_change();
                                                      k_this.set_color_order_status();
                                                   },
        "ajax": {
                    "url": "/all_orders",
                    "type": "GET",
                    "data": {'filters':filters},
                    "dataSrc": function ( json )
                    {
                      // $(".loader").hide();
                      k_this.data_table_params = $.param($('.admin-table').DataTable().ajax.params());
                      k_this.change_url("admin", '/admin?'+ k_this.data_table_params)
                      $(".Export-Btn").attr("href", '/all_orders_details.csv?'+ k_this.data_table_params)
                      return json.data;
                    }
                },
        "columns": [

                    { "data": "order_number",
                      "render": function ( data, type, row )
                      {
                        return '<label class="Admin-Page__order-number">'+data+'</label>';
                      }
                    },
                    { "data": "username",
                      "render": function ( data, type, row )
                      {
                        var user_name = data
                        var email = '<div class = "Admin-Page__Email">'+row.email+'</div>'
                        return data+"<br>"+email;
                      }
                    },
                    {
                      'data':"product_names",
                      "render": function ( data, type, row )
                      {
                        // var names = '';
                        // data = data.split(',')
                        // for( var element of data)
                        // {
                        //     names = names+"<br>"+element;
                        // }
                        return data
                      }
                    },
                    { "data": "order_placed_at",
                      "render": function ( data, type, row )
                      {
                        return data
                      }
                    },
                    { "data": "amount",
                      "render": function ( data, type, row )
                      {
                        return data;
                      }
                    },
                    { "data": "tracking_number",
                      "render": function ( data, type, row )
                      {
                        if(row['only_shareable'])
                        {
                          return '-';
                        }
                        else
                        {
                            if(data==null || data == "")
                            {
                              return '-';
                            }
                            else
                            {
                              return '<label class="Admin-Page__tracking-number">'+data+'</label>';
                            }
                        }
                      }
                    },
                    { "data": "order_status",
                      "render": function ( data, type, row )
                      {
                          if(row['only_shareable'])
                          {
                            var select = '-';
                          }
                          else
                          {
                            if(data=="Order Placed" || data == null)
                            {
                              var disabled = false;
                              var options = '<option value = 1 selected disabled> Order Placed </option>'+
                                            '<option value = 2 > Shipped </option>'+
                                            '<option value = 3 > Cancelled </option>'
                            }
                            else if(data=="Shipped")
                            {
                              var disabled = false;
                              var options = '<option value = 1 disabled> Order Placed </option>'+
                                            '<option value = 2 selected disabled> Shipped </option>'+
                                            '<option value = 3 > Cancelled </option>'
                            }
                            else if(data=="Cancelled")
                            {
                              var disabled = true;
                              var options = '<option value = 1 disabled> Order Placed </option>'+
                                            '<option value = 2 disabled> Shipped </option>'+
                                            '<option value = 3 selected disabled> Cancelled </option>'
                            }
                            if (disabled == true){
                              var select = '<select class = "order-status" data-id="'+row.id+'" disabled>'+options+'</select>'
                            }
                            else{
                              var select = '<select class = "order-status" data-id="'+row.id+'">'+options+'</select>'
                            }
                          }
                          return select;
                      }
                    },
                    {
                      "orderable": false,
                      mRender: function (data, type, row)
                      {

                          var linkEdit = '<a href= "javascript:void(0)" class= "view_detail" data-row-id="'+row.id+'">View Details</a>'
                          var links = linkEdit;

                          if(row.books_are_available)
                          {
                            var book_download = '<a href = "/admin/download_book/'+row.id+'" target="_blank"  class= "download_book" data-id="'+row.id+'">Download Book</a>';
                            links = links + book_download
                          }
                          if(row.shareable_link_are_available)
                          {
                            var shareable_link = '<a href = "'+row.shareable_link+'" target= "_blank" class= "download_book" data-id="'+row.id+'">Download Shareable Link</a>';
                            links = links + shareable_link
                          }
                          if(row.tent_cards_are_available)
                          {
                            var tent_card = '<a href = "'+row.tent_card_link+'" target= "_blank" class= "download_book" data-id="'+row.id+'">Download Tent Card</a>';
                            links = links + tent_card
                          }
                          if( row.books_are_available && row.shareable_link_are_available && row.tent_cards_are_available)
                          {
                            var linkDeactivate = ''
                          }

                          return links
                     }
                    }
                  ]
      });
    }

    get_filters()
    {
        var start_date    = $("#filters_start_date").val();
        var end_date      = $("#filters_end_date").val();
        var product_names = $('#product_id').val();
        var order_status  = this.get_order_status();
        var filter_data   = {'start_date': start_date, 'end_date': end_date, 'order_status': order_status, 'product_names': product_names}
        return filter_data
    }

    get_order_status()
    {
        var order_statuses = [];

        if($('#filters_order_placed').prop('checked'))
        {
          var placed = $('#filters_order_placed').val()
          order_statuses.push(placed);
        }
        if($('#filters_shipped').prop('checked'))
        {
          var shipped = $('#filters_shipped').val()
          order_statuses.push(shipped);
        }
        if($('#filters_cancelled').prop('checked'))
        {
          var cancelled = $('#filters_cancelled').val()
          order_statuses.push(cancelled);
        }

        return order_statuses;
    }

    destroy_datatable()
    {
        Admin_Table.clear();
        Admin_Table.destroy();
    }

    reset_form(form)
    {
       form[0].reset();
    }

    filter_form_validation()
    {
              var k_this = this;
              var form_class           = ".Filters__Form";
              var required_form_fields = ['filters[start_date]','filters[end_date]'];
              var form_validation      = new FormValidation(form_class);
              form_validation.required_field_validation(required_form_fields);
              form_validation.formvalidate();
    }

    change_url(page, url)
    {
          if (typeof (history.pushState) != "undefined") {
              var obj = { Page: page, Url: url };
              history.pushState(obj, obj.Page, obj.Url);
          } else {
              alert("Browser does not support HTML5.");
          }
    }

    is_date_range_valid(start_date_string, end_date_string)
    {
      var start_date = this.get_date_from_string(start_date_string);
      var end_date = this.get_date_from_string(end_date_string);

      if(start_date!=null && end_date != null)
      {
        if(start_date <= end_date)
        {
          this.remove_admin_fliter_date_error();
          return true;
        }
        else
        {
          this.set_admin_filter_date_error();
          return false;
        }
      }
    }

    set_admin_filter_date_error()
    {
      $('#filters_end_date + .Form__ErrorLabel').text("Please select the valid range");
    }

    remove_admin_fliter_date_error()
    {
      $('#filters_start_date + .Form__ErrorLabel').text(" ")
      $('#filters_end_date + .Form__ErrorLabel').text(" ")
    }

    get_date_from_string(string_date)
    {
      var date = string_date.split('-')
      var year= isNaN(parseInt(date[2])) ? null : parseInt(date[2]);
      var month = isNaN(parseInt(date[1])) ? null : parseInt(date[1]-1);
      var day = isNaN(parseInt(date[0])) ? null : parseInt(date[0]);

      if(date == null || month == null || year == null)
      {
        return null;
      }
      else
      {
        return new Date(year, month, day)
      }
    }

    send_admin_filter()
    {
      var k_this = this;
      $('.Apply').click(function(event)
      {
        event.preventDefault();
        var start_date_string = $('#filters_start_date').val();
        var end_date_string   = $('#filters_end_date').val();
        var form              = $(".Filters__Form");


          if( $(form).valid())
          {
            if( k_this.is_date_range_valid(start_date_string, end_date_string) )
            {
              k_this.destroy_datatable();
              var filters = k_this.get_filters();
              k_this.initialize_admin_table(filters);
            }
            else
            {
              k_this.set_admin_filter_date_error();
            }
          }

      })
    }

    open_modal()
    {
      $('.view_detail').click(function()
      {
          var order_id = $(this).attr('data-row-id');
          $.ajax({
                    url: "/admin/"+order_id+"/view_detail",
                    method: "GET"
                })
      });
    }

    close_modal()
    {
      $('.ok_button').click(function()
      {
         $('.Modal').hide();
      })
    }

    order_status_change()
    {
      $('.order-status').on('change',function(event)
      {
          var order_id = $(this).parents('tr').find('.view_detail').attr('data-row-id');

          if(event.currentTarget.value=="3")
          {
            $(this).prop('disabled', true);
          }

          $.ajax({
                    url: "/admin/"+order_id+"/order_status_change",
                    method: "POST",
                    data: {'order_status_value': event.currentTarget.value}
                })
      })
    }

    tracking_form_validation()
    {
        var k_this = this;
        var form_class           = ".Tracking-Number__Form";
        var required_form_fields = ['tracking_number'];
        var form_validation      = new FormValidation(form_class);
        form_validation.required_field_validation(required_form_fields);
        form_validation.formvalidate();
    }

    tracking_number_modal_close_on_close_icon()
    {
         $('.tracking_number_modal').find('.Modal__CloseIcon').click(function()
         {
           var value = $('#order_id').val();
           $('.tracking_number_modal').hide();
           $("[data-row-id="+value+"]").parents('tr').find('.order-status').val(1).trigger('change.select2');
        })
    }

    tracking_number_modal_close_on_submit()
    {
      $('.tracking_number_modal').hide();
    }

    set_color_order_status()
    {
      $('.order-status').each(function()
      {
        if( $(this).find('option:selected').val() == "1" )
        {
          $(this).parent().find(".select2-selection__rendered").css('color','#383838');
        }
        else if( $(this).find('option:selected').val() == "2" )
        {
          $(this).parent().find(".select2-selection__rendered").css('color','#FD856A');
        }
        else if( $(this).find('option:selected').val() =="3" )
        {
          $(this).parent().find(".select2-selection__rendered").css('color','#E02020');
        }
      })
    }

    status_change_to_shipped()
    {
      $('.Modal').show();
      $('.Modal').addClass('tracking_number_modal');
      $('.Modal__Section').css('padding','34px 40px 40px 40px');
      $('.Modal__Section').css('width','500px');
    }
}

export default Admin;
