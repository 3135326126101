import FormValidation from '../../common/formvalidation';

class ForgorPassword
{

  form_validation()
  {
    var form_class           = ".ForgotPasswordPage__Form";
    var required_form_fields = ['user[email]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

}

export default ForgorPassword;
