class Modal
{
    closeModal()
    {
        $('.Modal').hide();
        $('body').css('overflow','auto');
    }


}

export default Modal;
