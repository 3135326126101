import FormValidation from '../../common/formvalidation';

class ContactUs
{

  form_validation()
  {
    var form_class           = ".ContactPage__Form";
    var required_form_fields = ['name','email','subject','message'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.g_recaptcha_formvalidate();
  }

}

export default ContactUs;
