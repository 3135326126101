import textfit from 'textfit/textFit.js';
import InputField from '../../common/inputfieldmax';
import FormValidation from '../../common/formvalidation';

class CustomizeBook
{
      form_validation()
      {
        var form_class           = ".BookCover__Form";
        var required_form_fields = ['product[book_item_attributes][book_detail_attributes][book_detail_template_values_attributes][0][value]',
                                    'product[book_item_attributes][cover_page_attributes][id]'];
        var form_validation      = new FormValidation(form_class);
        form_validation.required_field_validation(required_form_fields);
        form_validation.formvalidate('HTML',true);
      }

      formatDate(dateString) {
        // dateString should be of format 'yyyy-mm-dd'
        const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
  
        const dateParts = dateString.split('-');
        
        const year = dateParts[0];
        const month = monthNames[parseInt(dateParts[1], 10) - 1]; // because month index starts from 0
        const day = dateParts[2];
        
        return `${day} ${month} ${year}`;
      }

      ordinalize(number) {
        if (isNaN(number) || number < 1) {
          return number;
        } else {
          var lastDigit = number % 10;
          if (lastDigit === 1 && number !== 11) {
            return number + "st";
          } else if (lastDigit === 2 && number !== 12) {
            return number + "nd";
          } else if (lastDigit === 3 && number !== 13) {
            return number + "rd";
          } else {
            return number + "th";
          }
        }
      }

      set_font_sizes()
      {
         $('.Book-Template1__Input1').inputfit({minSize: 8, maxSize:18});
         $('.Book-Template1__Input2').inputfit({minSize: 8, maxSize:18});

         new InputField().maxlength($('.Book-Template1__Input1'),40);
         new InputField().maxlength($('.Book-Template1__Input2'),40);

         $('.Book-Template2__Input1').inputfit({minSize: 8, maxSize:36});
         $('.Book-Template2__Input2').inputfit({minSize: 8, maxSize:20});

         new InputField().maxlength($('.Book-Template2__Input1'),40);
         new InputField().maxlength($('.Book-Template2__Input2'),40);

         $('.Book-Template3__Input1').inputfit({minSize: 8, maxSize:30});
         $('.Book-Template3__Input2').inputfit({minSize: 8, maxSize:50});
         $('.Book-Template3__Input3').inputfit({minSize: 8, maxSize:30});

         new InputField().maxlength($('.Book-Template3__Input1'),40);
         new InputField().maxlength($('.Book-Template3__Input2'),40);
         new InputField().maxlength($('.Book-Template3__Input3'),40);
      }

      set_submit_button(is_form_validate)
      {
        if(is_form_validate)
        {
          $('.BookCustomisationPage__Submit').attr('disabled',false);
          $('.tooltiptext').css('display','none');
        }
        else
        {
          $('.BookCustomisationPage__Submit').attr('disabled',true);
          $('.tooltiptext').css('display','block');
        }
      }
}

export default CustomizeBook;
