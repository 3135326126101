import Plyr from 'plyr';

class CustomPlyr {

  constructor() {
    this.customplyr = null;
  }


  initialize() {
    this.customplyr = new Plyr('#player', {
      ratio: '1:1'
    });
  }

  standard_initialize() {
    this.customplyr = new Plyr('#player', {
      ratio: '16:9'

    });
  }

  destroy_video_modal() {
    if(this.customplyr != null )
    {
      this.customplyr.destroy();
    }
  }

  show_video_modal()
  {
    $('.Video__Modal').show();
    $('.Video__Modal .Modal__Section').css({'width':'460px', 'padding':'0px'});
    $('body').css('overflow','hidden');
  }

  show_standard_video_modal() {
    $('.Video__Modal').show();
    $('body').css('overflow','hidden');
  }

  hide_video_modal() {
    $('.Video__Modal').hide();
    $('body').css('overflow','inherit');
  }

  set_video_source(video_src) {
    $('#player').find('source').attr('src', video_src);
    $('#player')[0].load();
  }

}

export default CustomPlyr;
