class PasswordField
{

  show_password(field_class)
  {

    var element        =  field_class;
    var parent_element = $(element).parentsUntil('Form__PasswordSection')[0];
    this.change_eyeslash_icon_to_eye(parent_element);
    this.change_password_to_text(parent_element);
  }

  hide_password(field_class)
  {
    var element        = field_class;
    var parent_element = $(element).parentsUntil('Form__PasswordSection')[0];
    this.change_eye_icon_to_eyeslash(parent_element);
    this.change_text_to_password(parent_element);
  }

  change_eye_icon_to_eyeslash(parent_element )
  {
    var eye_icon = $(parent_element).find('.fa-eye');
    eye_icon.removeClass('fa-eye');
    eye_icon.addClass('fa-eye-slash');
  }

  change_eyeslash_icon_to_eye(parent_element)
  {
    var eye_icon = $(parent_element).find('.fa-eye-slash');
    eye_icon.removeClass('fa-eye-slash');
    eye_icon.addClass('fa-eye');
  }

  change_password_to_text(parent_element)
  {
    var password_field = $(parent_element).find('.Form__Input');
    password_field.attr('type', 'text');
  }

  change_text_to_password(parent_element)
  {
    var password_field = $(parent_element).find('.Form__Input');
    password_field.attr('type', 'password');
  }

}

export default PasswordField;
