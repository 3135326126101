import AddressDetails from './addressdetails';
import InputField from '../../common/inputfieldmax';

$(document).on('turbolinks:load',()=>
{

    var addressdetails = new AddressDetails();
    addressdetails.form_validation();
    new InputField().maxlength($('#address_name'),255);
    new InputField().maxlength($('#address_contact_no'),20);
    new InputField().maxlength($('#address_address_line_1'),255);
    new InputField().maxlength($('#address_address_line_2'),255);
    new InputField().maxlength($('#address_city'),255);
    new InputField().maxlength($('#address_zipcode'),15);

    // ----------  On Confirm Deletion Click Show Modal -----------
        $('.AddressPage__Remove').click(function(){
            $('.ConfirmationModal').css('display','block');
            var address_id = $(this).attr('data-address-id');
            $('.ConfirmationModal .ConfirmationModal__Accept').attr('href','/addresses/'+address_id);

        })
    // ------------------------------------------------------------

})
