  // error handler for stripe cards
  class ErrorsStripe
  {

    getError(error){
      switch(error.code) {
        case 'incomplete_number':
          return 'Required'
        case 'incomplete_expiry':
          return 'Required'
        case 'incomplete_cvc':
          return 'Required'
        case 'invalid_expiry_year_past':
          return 'Your card expiry is invalid.'
        case 'invalid_expiry_month_past':
          return 'Your card expiry is invalid.'
        case 'invalid_expiry_year':
          return 'Your card expiry is invalid.'
        case 'Invalid value for eu_vat.':
          return 'VAT number is invalid.'
        default:
          return error.message
      }
    }
  }

  export default ErrorsStripe;
