import CustomizeBook from './customize_book';


$(document).on('turbolinks:load',()=>
{
    var book_card        = new CustomizeBook();
    var book_form        = $('.BookCover__Form');
    var theme_name       = $('#theme-name').text();

    if(book_form.length > 0)
    {
      var is_form_validate = $('.BookCover__Form').valid();
      book_card.set_submit_button(is_form_validate);
    }

    // Replace the name on template preview on key up
    $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_name").keyup(function(){
      if($(this).val() != '') {
        $('#name').text($(this).val());
      }
      else {
        $('#name').text('Robinson');
      }
    });

    if (['diamond-love', 'young-couple'].includes(theme_name)) {
      // Replace the partner name on template preview on key up
      $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_partner_name").keyup(function(){
        if($(this).val() != '') {
          $('#partner_name').text($(this).val());
        }
        else {
          $('#partner_name').text('Kiyara');
        }
      });
    }

    if (['square-birthday', 'four-square-birthday'].includes(theme_name)) {
      // Replace the age on template preview on key up
      $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_age").on('mouseup, keyup', function(){
        if($(this).val() != '') {
          theme_name == 'square-birthday' ? $('#age').text($(this).val()) : $('#age').text(book_card.ordinalize($(this).val()));
        }
        else {
          theme_name == 'square-birthday' ? $('#age').text('1') : $('#age').text(book_card.ordinalize('1'));
        }
      });
    }

    if (['young-couple'].includes(theme_name)) {
      // Replace the years on template preview on key up
      $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_years").on('mouseup, keyup',function(){
        if($(this).val() != '') {
          $('#years').text($(this).val());
        }
        else {
          $('#years').text('25');
        }
      });
    }

    if (['diamond-love', 'young-couple', 'four-square-birthday'].includes(theme_name)) {
      // Replace the event date on template preview on change
      $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_event_date").change(function(){
        const enterred_date = $('#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_event_date').val();
        if (theme_name == 'young-couple') {
          let date = enterred_date.split('-')
          $('#event_date').text(`${date[2]}.${date[1]}.${date[0]}`);
        } else {
          $('#event_date').text(book_card.formatDate(enterred_date));
        }
      });
    }

    // Limit the number of picures selected for upload
    $("#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures").change(function() {
      let totalPictures = 1;  // Set your limit
      if (theme_name == 'four-square-birthday') {
        totalPictures = 4
      }
      if (this.files.length !== totalPictures) {
        alert(`You need to select ${totalPictures} image${totalPictures == 4 ? 's' : ''}.`);
        this.value = '';  // Reset the input field
      }
    });

    $('#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures').change(function() {
      let picture = $('#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures')[0].files[0]
      let picture2, picture3, picture4;
      if (theme_name == 'four-square-birthday') {
        picture2 = $('#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures')[0].files[1]
        picture3 = $('#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures')[0].files[2]
        picture4 = $('#product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures')[0].files[3]
      }
      if (theme_name != 'four-square-birthday' && picture) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(picture);
        fileReader.addEventListener("load", function () {
          $('.Book-Cover__Front-Image-Section').css('backgroundImage', 'url(' + this.result + ')');
          if (theme_name == 'diamond-love') {
            $('.Book-Cover__Front-Secondary-Image').css('backgroundImage', 'url(' + this.result + ')');
          }
        });
      }
      if (theme_name == 'four-square-birthday' && picture && picture2 && picture3 && picture4) {
        const fileReader = new FileReader();
        const fileReader2 = new FileReader();
        const fileReader3 = new FileReader();
        const fileReader4 = new FileReader();
        fileReader.readAsDataURL(picture);
        fileReader2.readAsDataURL(picture2);
        fileReader3.readAsDataURL(picture3);
        fileReader4.readAsDataURL(picture4);
        fileReader.addEventListener("load", function () {
          $('.Book-Cover__Front-Image-1').css('backgroundImage', 'url(' + this.result + ')');
        });
        fileReader2.addEventListener("load", function () {
          $('.Book-Cover__Front-Image-2').css('backgroundImage', 'url(' + this.result + ')');
        });
        fileReader3.addEventListener("load", function () {
          $('.Book-Cover__Front-Image-3').css('backgroundImage', 'url(' + this.result + ')');
        });
        fileReader4.addEventListener("load", function () {
          $('.Book-Cover__Front-Image-4').css('backgroundImage', 'url(' + this.result + ')');
        });
      }
    })

    $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_name, #product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_partner_name, #product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_age, #product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_years").keyup(function()
    {
        is_form_validate = $('.BookCover__Form').valid();
        book_card.set_submit_button(is_form_validate);
    })

    $("#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_event_date, #product_book_item_attributes_cover_page_attributes_content_cover_attributes_pictures").change(function()
    {
        is_form_validate = $('.BookCover__Form').valid();
        book_card.set_submit_button(is_form_validate);
    })


    book_card.form_validation();
    book_card.set_font_sizes();

    $('#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_name').focus();

})


$(window).on('load', function()
{
  $('#product_book_item_attributes_book_detail_attributes_book_detail_template_values_attributes_name').focus();
})
