class Loader
{
  show(type, message=null) {
    if (type == 'Video'){
      var icon_type = '<i class="fas fa-camera-retro loader-inner"></i>';
      var text = 'Please wait while we create your video preview';
    }
    else if(type=="Custom")
    {
        var icon_type = ' <i class="fas fa-spinner fa-spin loader-inner"></i>';
        var text = message;
    }
    else if(type=="Custom2")
    {
        var icon_type = '<i class="fas fa-camera-retro loader-inner"></i>';
        var text = message;
    }
    else{
      var icon_type = '<i class="fas fa-money-check loader-inner"></i>';
      var text = 'Please wait while we process your payment';
    }

    $('.lds-ring').append(icon_type);
    $('.loader-text').html(text);
    $('.loader').removeClass('hidden');
  }


  hide() { setTimeout(function(){
    $('.loader').addClass('hidden');
    $('.loader-text').html('');
    $('.loader-inner').remove();
     }, 2000);}

     hide_without_delay() { setTimeout(function(){
       $('.loader').addClass('hidden');
       $('.loader-text').html('');
       $('.loader-inner').remove();
        });}
}
export default Loader;
