import FormValidation from '../../common/formvalidation';

class AddressDetails
{
  form_validation()
  {
    var form_class           = ".DeliveryDetails__Form";
    var required_form_fields = ['address[name]','address[contact_no]','address[address_line_1]','address[address_line_2]', 'address[country_id]','address[city]','address[state]','address[zipcode]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate('js',false);
  }
}

export default AddressDetails;
