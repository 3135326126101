// This file contains the operation for the time screen which count from the 3 to 1
// It show the image image

import VideoFunctionality from './createVideo';
import CameraFunctionality from './captureImage';
import Loader from '../../components/loader';
class TimeScreenFunctionality
{

  constructor()
  {
      this.videofunc  = new VideoFunctionality();
      this.camerafunc = new CameraFunctionality(this.videofunc);
  }


  async imagecapturingstartCountDown(countDownNumber)
  {

     var k_this = this;
     $('.Timescreen__CountDown').css('display','grid');
     var initialize_successfull = this.camerafunc.initializeVideoScreen();

  }


  onRecordingVideosStop()
  {
    this.videofunc.recordingBegins_StopRecording();
  }

  embeddedCountDownInPage(number)
  {
    $('.CaptureImagePage__CountDown').text(number);
  }


  submit_form()
  {
    var name            = $('#product_guest_name').val();
    var email           = $('#product_guest_email').val();
    var message         = $('#product_guest_message').val();
    var code            = $('#code').val();
    var slug            = $('#slug').val();
    var image_blob      = this.camerafunc.image_blob;
    var video_blob      = this.videofunc.video_blob;
    var crop_values     = $('#product_guest_crop_values').val();

    var formData = new FormData();  //Why we need this
    formData.append('product_guest[name]',name);
    formData.append('product_guest[email]',email);
    formData.append('product_guest[message]', message);
    formData.append('product_guest[picture]', image_blob, 'screenshot.png');
    formData.append('product_guest[video]', video_blob, 'video_recording.mkv');
    formData.append('code', code);
    formData.append('product_guest[crop_values]', crop_values);


    if($('#GuestBookEntryPage__AdditionalDetails').valid())
    {
      new Loader().show('Custom2');
      $.ajax(
              {
                url:'/products/'+slug+'/guests',
                method:'post',
                processData: false,  //Why we need this
                contentType: false,  //Why we need this
                data: formData,
                success: (results) => {
                                        $('.GuestAdditionDetails__Submit').attr('disabled', false);
                                        new Loader().hide();
                                      }
              }
            )
    }
  }
}

export default TimeScreenFunctionality;