
class CustomizeTentCard
{

      default_select()
      {
          if($('.TentCustomisationPage__ThemeRadioButton:checked').val() == undefined)
          {
            $('.TentCustomisationPage__BackgroundThemeNameSection').first().find('.TentCustomisationPage__ThemeRadioButton').click();
            $('.TentCustomisationPage__FontColorSection').first().find('.TentCustomisationPage__FontColorRadioButton').click();
          }
          else
          {
            var theme_color_element = $('.TentCustomisationPage__ThemeRadioButton:checked');
            var font_color_element = $('.TentCustomisationPage__FontColorRadioButton:checked');
            this.change_tent_card_background_color(theme_color_element);
            this.change_tent_card_font_color(font_color_element);
          }
      }

      change_tent_card_background_color(element)
      {
         var color = this.get_color_from_selected_theme(element);
         $('.TentCustomisationPage__CardSection').css('background-color',color);
         $('.BookPage').removeClass('Selected');
         $(element).parent('.TentCustomisationPage__BackgroundThemeNameSection').find('.BookPage').addClass('Selected');

      }

      change_tent_card_font_color(element)
      {
        var color = this.get_color_from_selected_font_color(element);
        $('.TentCustomisationPage__Text').css('color',color);
        $('.TentCustomisationPage__Card-Info').css('color',color);
        $('.TentCustomisationPage__Input').css('color',color);
        $('.TentCustomisationPage__FontColor').removeClass('Selected');
        $(element).parent('.TentCustomisationPage__FontColorSection').find('.TentCustomisationPage__FontColor').addClass('Selected');
      }

      change_tent_card_input_background_color(element)
      {
          var color = this.get_color_from_selected_font_color(element);
          if(color == "rgb(255, 255, 255)")
          {
              $('.TentCustomisationPage__Input').css('background-color','rgba(0,0,0,0.5)');
          }
          else
          {
              $('.TentCustomisationPage__Input').css('background-color','rgb(255, 255, 255)');
          }
      }

      get_color_from_selected_theme(element)
      {
            var color = $(element).parent('.TentCustomisationPage__BackgroundThemeNameSection').find('.BookPage__Section').css('background-color');
            return color;
      }

      get_color_from_selected_font_color(element)
      {
          var color = $(element).parent('.TentCustomisationPage__FontColorSection').find('.TentCustomisationPage__FontColor').css('background-color');
          return color;
      }

      set_submit_button(is_form_validate)
      {
        if(is_form_validate)
        {
          $('.TentCustomisationPage__Submit').attr('disabled',false);
          $('.tooltiptext').css('display','none');
        }
        else
        {
          $('.TentCustomisationPage__Submit').attr('disabled',true);
          $('.tooltiptext').css('display','block');
        }
      }
}

export default CustomizeTentCard;
