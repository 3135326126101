import Product from './product';
import InputField from '../../common/inputfieldmax';
global.cropper = '';

$(document).on('turbolinks:load',()=>
{
    var product = new Product();
    new InputField().maxlength($('#product_name'),50);
    new InputField().maxlength($('#product_subject'),50);
    new InputField().maxlength($('#product_welcome_detail_attributes_title'),200);

    // Character counter for Title
    $('#product_welcome_detail_attributes_title').on('input', function() {
        const charCount = $(this).val().length;
        const charsLeft = 200 - charCount;
        if (charsLeft <= 20) {
            $('.WelcomeDetailsPage__Input-Character_Count').css('color', 'red');
          } else {
            $('.WelcomeDetailsPage__Input-Character_Count').css('color', 'grey');
          }
        $('.WelcomeDetailsPage__Input-Character_Count').text(`${charsLeft} characters left`);
    });

    new CustomSelect2().initialize_select2();
    product.set_step();
    product.step_1_form_validation();
    product.step_2_open_image_file();
    product.step_2_form_validation();

    $('#product_welcome_detail_attributes_picture').change(function()
    {
        var url = window.URL.createObjectURL(this.files[0]);
        product.step_2_set_image(url);
        product.step_2_set_original_image_url(url);
        $('#product_welcome_detail_attributes_picture').parent().find('.Form__ErrorLabel').css('display','none');
        $('#product_welcome_detail_attributes_picture').parent().find('.Form__ErrorLabel').text('');
    })

    $('.WelcomeDetailsPage__CropIconSection').click(()=>{
                                                            var url = $('#original_image_url').val();

                                                            product.step_2_set_image(url);
                                                            product.step_2_initialize_cropping_area();
                                                            product.step_2_change_crop_icon_to_done_icon();
                                                            product.step_2_disable_next_button();
                                                        })

    $('.WelcomeDetailsPage__DoneIconSection').click(()=>{
                                                            product.step_2_crop_image();
                                                            product.step_2_change_done_icon_to_crop_icon();
                                                            product.step_2_destroy_cropper();
                                                            product.step_2_enable_next_button();
                                                         })

    product.step_3_select_template();
    if (window.location.href.includes('/select-book-template')) {
      product.step_3_initialize_splide();
    }
    product.step_4_change_tent_card_amount();


    $('.Book-Template1__ImageIcon, .Book-Template1__Image').click(function(){
        $('.Book-Template1__SelectImage').click();
    })

    $('.Book-Template1__SelectImage').change(function(event)
    {
          var image_url = URL.createObjectURL(event.target.files[0]);
          $('.Book-Template1__Image').css('background-image',"url("+image_url+")");
          $('.Book-Template1__ImageIcon').css('display', 'none');
          $('.Book-Template1__Image').css('display','block');
    });


    if ($('.WelcomeDetailsPage__ImageInput').length > 0){
            if ($('.WelcomeDetailsPage__DropFile').is(":visible")){
                var $dropzone = document.querySelector('.WelcomeDetailsPage__DropFile');
            }
            else{
                var $dropzone = document.querySelector('.WelcomeDetailsPage__DropFile2');
            }

            var input = document.getElementById('product_welcome_detail_attributes_picture');
            $dropzone.ondragover = function (e) {
            e.preventDefault();
            this.classList.add('dragover');
            };
            $dropzone.ondragleave = function (e) {
                e.preventDefault();
                console.log('check2');
                this.classList.remove('dragover');
            };
            $dropzone.ondrop = function (e) {
                e.preventDefault();
                this.classList.remove('dragover');
                var imageType = /image.*/;
                if (e.dataTransfer.files[0].type.match(imageType)) {
                   // it's an image, process it
                   input.files = e.dataTransfer.files;
                   var url = window.URL.createObjectURL(input.files[input.files.length -1]);
                    product.step_2_set_image(url);
                    product.step_2_set_original_image_url(url);
                    $('#product_welcome_detail_attributes_picture').parent().find('.Form__ErrorLabel').css('display','none');
                    $('#product_welcome_detail_attributes_picture').parent().find('.Form__ErrorLabel').text('');
                }

            }
    }



    /* Create a new book Modal Start */

    $('#mycreate-button').click(function(event){
        showModal();
        event.stopPropagation(); 
    });
    $('#modalClose').click(function(){
        hideModal();
    });
  
    // Do nothing when clicking on the modal content
    $('.createnew-modal-content').click(function(event){
        event.stopPropagation(); 
    });

})


function showModal(){
  $('#myModal').fadeIn('slow');
    (function fun(){
      $('.createnew-modal-content').css({'transform':'translateY(-50px)'});
    })();
}

function hideModal(){
  $('#myModal').fadeOut('fast');
    (function fun2(){
      $('.createnew-modal-content').css({ 'transform':'translateY(0px)' });
    })();
}



$(document).on("click", function () {
    //click outside of ".nav__dropdown" class itself and menu will be hidden
  hideModal();
});

/* Create a new book Modal End */