import CustomValidators from './custom_validation';

class FormValidation
{
    constructor(form_id)
    {
      this.rules = {};
      this.messages = {};
      this.form_id = form_id;
      new CustomValidators().is_value_equal_to_current_date();
      new CustomValidators().custom_checkbox_validation();
      new CustomValidators().customise_book_input_type_file_validation();
    }

    formvalidate(type="HTML", g_recaptcha_present=false)
    {
      $(this.form_id).validate(
        {
          rules: this.rules,
          messages: this.messages
          // submitHandler: function(form,event)
          // {
          //   event.preventDefault();
          //   if( $(form).valid())
          //   {
          //            // var loader = new Loader();
          //           // loader.show();
          //     if(g_recaptcha_present && grecaptcha.getResponse() == "")
          //     {
          //            $("#recaptcha_error_msg").html(I18n.validation.captcha);
          //            return false;
          //     }
          //     else
          //     {
          //         if(type=="HTML")
          //         {
          //             form.submit();
          //         }
          //         else
          //         {
          //           // form.trigger('submit.rails');
          //           // Rails.fire(this, 'submit');
          //           // form.submit();
          //         }
          //     }
          //   }
          // }
      });
    }

    g_recaptcha_formvalidate()
    {

      $(this.form_id).validate(
        {
          rules: this.rules,
          messages: this.messages,
          submitHandler: function(form,event)
          {
            event.preventDefault();
            if( $(form).valid())
            {
                     // var loader = new Loader();
                    // loader.show();
              if(window.location.href.includes('/contact-us'))
              {
                  if(form.classList.contains('Footer__Subscribing-Form'))
                  {
                    var recap = $('.g-recaptcha-response')[1];
                    var index = recap.id.split('-')[3];
                    if( grecaptcha.getResponse(index) == "")
                    {
                           $(form).find("#recaptcha_error_msg").html(I18n.validation.captcha);
                           return false;
                    }
                    else
                    {
                          Rails.fire(this, 'submit');
                    }
                  }
                  else
                  {

                    if( grecaptcha.getResponse() == "")
                    {
                           $(form).find("#recaptcha_error_msg").html(I18n.validation.captcha);
                           return false;
                    }
                    else
                    {
                          form.submit();
                    }
                  }
              }
              else
              {
                  if( grecaptcha.getResponse() == "")
                  {
                         $(form).find("#recaptcha_error_msg").html(I18n.validation.captcha);
                         return false;
                  }
                  else
                  {
                        Rails.fire(this, 'submit');
                  }
              }
            }
          }
      });
    }


    required_field_validation(field_names)
    {
        for (name of field_names)
        {
            this.rules[name] = {required: true};
            this.messages[name] = {required: I18n.required.message};
        }
     }

     fields_matches_validations(field_names)
     {
          for (let index in field_names)
          {
            if(field_names[index].field_to_be_matched_name in this.rules)
            {
              this.rules[field_names[index].field_to_be_matched_name]["equalTo"]    = field_names[index].original_field_id;
              this.messages[field_names[index].field_to_be_matched_name]["equalTo"] = I18n.match.message;
            }
          }
      }

     required_select_file_image(field_names)
     {

          $.validator.addMethod("select_file_validation", function(value, element)
          {
              if($('#original_image_url').val().length == 0 )
              {
                return false;
              }
              else
              {
                 return true;
              }
          })

          for (name of field_names)
          {
              this.rules[name] = {select_file_validation: true};
              this.messages[name] = {select_file_validation: I18n.required.message};
          }
       }

     fields_current_date_validations(field_names)
     {
         for (name of field_names)
         {
           this.rules[name] = {is_value_equal_to_current_date: true};
           this.messages[name] = {is_value_equal_to_current_date: I18n.validation.is_value_equal_to_current_date};
         }
      }
}

export default FormValidation;
