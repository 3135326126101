import SelectThemeHeader from '../../components/select_theme_header';
import textfit from 'textfit/textFit.js';
import inputfit from '../../common/jquery.inputfit';

class CreateBook {

  setImageFaders(fade_ids)
  {
    $('.File-Type-And-Id').each(function(){
      var type = $(this).find('input:first').val();
      var type_id = $(this).find('input:last').val();
      var id = type + '-' + type_id;
      fade_ids.push(id);
    });

    $('.CreateBookMediaCard__Section').removeClass('disabled-section');
    fade_ids.forEach(function(id){
      $('.'+ id).addClass('disabled-section');
    });

  }

  find_fading_sections(fade_ids)
  {
    $('.File-Type-And-Id').each(function(){
      var type = $(this).find('input:first').val();
      var type_id = $(this).find('input:last').val();
      var id = type + '-' + type_id;
      fade_ids.push(id);
    });
    return fade_ids;
  }

  fadesidebar_sections(fade_ids)
  {
    $('.CreateBookMediaCard__Section').removeClass('disabled-section');
    fade_ids.forEach(function(id){
      $('.'+ id).addClass('disabled-section');
    });
  }

  set_font_sizes()
  {
    // textfit( $('.CreateBook__Page .CreateBookCard__Input'), {minFontSize:10, maxFontSize: 18, multiLine: true});
    // textfit( $('.CreateBook__Page .CreateBookCard__Input-square-birthday'), {minFontSize: 15, maxFontSize: 34, multiline: true});
    // textfit( $('.CreateBook__Page .CreateBookCard__SenderName-square-birthday'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
    // textfit( $('.CreateBook__Page .CreateBookCard__Input-round-baby'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
    // textfit( $('.CreateBook__Page .CreateBookCard__SenderName-round-baby'), {minFontSize: 16, maxFontSize: 34, multiLine: true});
  }
}

export default CreateBook;
