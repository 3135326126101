import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';

class GetEmailAddress
{
  form_validation()
  {
    var form_class           = "#Confirm-Email";
    var required_form_fields = ['user[email]','user[password]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

  password_icon_click()
  {
    var password = new PasswordField();
    $('#Confirm-Email .Form__PasswordIcon').click(function(){
                                                  if( $(this).hasClass('fa-eye-slash') )
                                                  {
                                                      password.show_password(this);
                                                  }
                                                  else if($(this).hasClass('fa-eye'))
                                                  {
                                                      password.hide_password(this);
                                                  }
                                               })
  }

  ToggleExistingUser()
  {
    if ($('.existing-user-field').hasClass('d-none'))
    {
      $('.existing-user-field').removeClass('d-none');
      $('.existing-user-field').find('input').prop('disabled', false)
      $('.Link-Existing').addClass('d-none');
      $('.Link-New').removeClass('d-none');
    }
    else{
      $('.existing-user-field').addClass('d-none');
      $('.existing-user-field').find('input').prop('disabled', true)
      $('.Link-Existing').removeClass('d-none');
      $('.Link-New').addClass('d-none');
    }
  }

}

export default GetEmailAddress ;
