class Coupon
{

  apply_coupon(coupon_code, discount_price, total_price, coupon_discount)
  {
    $('input[name="coupon_id"]').val(coupon_code);
    $('.discount_price').text(discount_price);
    $('.total_price').text(total_price);
    $('.CouponSection').hide();
    $('.CouponStatusSection').show();
    $('.CouponDiscount').text(coupon_discount);
  }

  remove_coupon(coupon_code, discount_price, total_price)
  {
    $('input[name="coupon_id"]').val(coupon_code);
    $('.discount_price').text(discount_price);
    $('.total_price').text(total_price);
    $('.CouponSection').show();
    $('.CouponStatusSection').hide();
    $('.CouponDiscount').text('');
    $('#coupon_code').val('');
  }
}

export default Coupon;
