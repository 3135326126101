import GuestBookEntryPage from './guest_book_entry_page';
import TimeScreenFunctionality from './timescreen';
import AdditionalDetails from './additional_details';
import CustomPlyr from '../../components/customplyr';
import InputField from '../../common/inputfieldmax';
global.cropper = '';


$(document).on('turbolinks:load', () => {
  const timescreen = new TimeScreenFunctionality();
  const guest_book_entry_page = new GuestBookEntryPage();
  const custom_plyr = new CustomPlyr()


  guest_book_entry_page.form_validation();
  guest_book_entry_page.open_image_file();

  $('#product_guest_picture').change(function() {
    var file = this.files[0]
    var blob = new Blob([file], {type: file.type});
    var url = window.URL.createObjectURL(blob);
    guest_book_entry_page.set_image(url);
    guest_book_entry_page.set_original_image_url(url);
    $('#product_guest_picture').parent().find('.Form__ErrorLabel').css('display','none');
    $('#product_guest_picture').parent().find('.Form__ErrorLabel').text('');
  })

  $("#product_guest_video").change(function() {
    $('#product_guest_video').parent().find('.Form__ErrorLabel').css('display','none');
    $('#product_guest_video').parent().find('.Form__ErrorLabel').text('');
  })

  $('.GuestBookEntryPage__CropIconSection').click(()=>{
    var url = $('#original_image_url').val();

    guest_book_entry_page.set_image(url);
    guest_book_entry_page.initialize_cropping_area();
    guest_book_entry_page.change_crop_icon_to_done_icon();
    guest_book_entry_page.disable_next_button();
  })

  $('.GuestBookEntryPage__DoneIconSection').click(()=>{
      guest_book_entry_page.crop_image();
      guest_book_entry_page.change_done_icon_to_crop_icon();
      guest_book_entry_page.destroy_cropper();
      guest_book_entry_page.enable_next_button();
  })

  if ($('.GuestBookEntryPage__ImageInput').length > 0){
    if ($('.GuestBookEntryPage__DropFile').is(":visible")){
        var $dropzone = document.querySelector('.GuestBookEntryPage__DropFile');
    }
    else{
        var $dropzone = document.querySelector('.GuestBookEntryPage__DropFile2');
    }

    var input = document.getElementById('product_guest_picture');
    $dropzone.ondragover = function (e) {
    e.preventDefault();
    this.classList.add('dragover');
    };
    $dropzone.ondragleave = function (e) {
        e.preventDefault();
        console.log('check2');
        this.classList.remove('dragover');
    };
    $dropzone.ondrop = function (e) {
        e.preventDefault();
        this.classList.remove('dragover');
        var imageType = /image.*/;
        if (e.dataTransfer.files[0].type.match(imageType)) {
           // it's an image, process it
           input.files = e.dataTransfer.files;
           var url = window.URL.createObjectURL(input.files[input.files.length -1]);
            guest_book_entry_page.set_image(url);
            guest_book_entry_page.set_original_image_url(url);
            $('#product_guest_picture').parent().find('.Form__ErrorLabel').css('display','none');
            $('#product_guest_picture').parent().find('.Form__ErrorLabel').text('');
        }

    }
  }

  $('#GuestBookEntryPage__UploadButton').click(() => {
    $('.GuestBookEntryPage__Step1').hide();
    $('.GuestBookEntryPage__StepUpload').show();
  })

  $('#GuestBookEntryPage__BackButton').click(() => {
    $('.GuestBookEntryPage__StepUpload').hide();
    $('.GuestBookEntryPage__Step1').show();
  })

  $('#GuestBookEntryPage__NextButton').click((e) => {
    var image = $("#product_guest_picture")[0].files[0]
    var video = $("#product_guest_video")[0].files[0]
    if (image && video) {
      var imageBlob = new Blob([image], {type: image.type});
      var videoBlob = new Blob([video], {type: video.type});
      timescreen.camerafunc.image_blob = imageBlob
      timescreen.videofunc.video_blob = videoBlob
      var video_url = window.URL.createObjectURL(videoBlob);
      var image_url = window.URL.createObjectURL(imageBlob);
      $('.GuestBookEntryPage__VideoSection').html("<img class='GuestBookEntryPage__VideoThumbnail' /><i class='fas fa-play-circle GuestBookEntryPage__VideoIcon'></i>");
      $('.GuestBookEntryPage__ImageSection').html(" <div class='GuestBookEntryPage__Image'></div>");
      $('.GuestBookEntryPage__Image').css('background', 'url(' + image_url + ')');
      $('#player').find('source').attr('src', video_url);
      $('#player')[0].load();
      $('.GuestBookEntryPage__StepUpload').hide();
      $('.GuestBookEntryPage__Step4').show();
      $('.GuestBookEntryPage__Heading').html('Review Your Entry');
    } else {
      if (!image) {
        $('#product_guest_picture').parent().find('.Form__ErrorLabel').text("Required");
      }
      if (!video) {
        $("#product_guest_video").next().text("Required");
      }
    }
  })

  $('#player').on("loadedmetadata", function(e) {
    let canvas = $("<canvas></canvas>");
    let aspectRatio = $('#player')[0].videoWidth / $('#player')[0].videoHeight;
    let scaledWidth = 150;
    let scaledHeight = 150;

    if ($('#player')[0].videoWidth > $('#player')[0].videoHeight) {
      // Landscape
      scaledHeight = 150 / aspectRatio;
    } else {
      // Portrait or Square
      scaledWidth = 150 * aspectRatio;
    }
    // Set canvas dimensions same as video dimensions
    canvas[0].width = scaledWidth;
    canvas[0].height = scaledHeight;

    // Set video current time to get some random image
    $('#player')[0].currentTime = 3;

    $('#player').one("timeupdate", function() {
      canvas.get(0).getContext("2d").drawImage($('#player')[0], 0, 0, scaledWidth, scaledHeight);
      $('.GuestBookEntryPage__VideoThumbnail').attr("src", canvas.get(0).toDataURL());
    })
  })

  $('#GuestBookEntryPage__ShootNowButton').click(() => {
    startButtonClick(timescreen);
  })

  $('.VideoPage__CloseButton').click(() => {
    timescreen.onRecordingVideosStop();
  })

  $('.GuestAdditionDetails__Submit').click(function(event)
  {
    if($('#GuestBookEntryPage__AdditionalDetails').valid())
    {
      timescreen.submit_form();
      // timescreen.add_video_and_image();
    }

  })

  $(document).on('click', '.GuestBookEntryPage__VideoIcon', function() {
    custom_plyr.initialize();
    custom_plyr.show_video_modal();
  })

  $('.GuestBookEntryPage').siblings('.Video__Modal').find('.Modal__CloseIcon').click(function() {
    custom_plyr.destroy_video_modal();
  })

  $('.GuestBookEntryPage__Proceed').click(function()
  {
      $('.GuestBookEntryPage__Step4').hide();
      $('.GuestBookEntryPage__Step5').show();
      $('.GuestBookEntryPage__Heading').html('Almost There');
  })

  $('.GuestBookEntryPage__PreviewImageButton').click(function(e) {
    e.preventDefault();
    $('.GuestBookEntryPage__Step5').hide();
    $('.GuestBookEntryPage__Step4').show();
    $('.GuestBookEntryPage__Heading').html('Review Your Entry');
  });

  new AdditionalDetails().form_validation();
})

function startButtonClick(timescreen) {

  timescreen.imagecapturingstartCountDown(5);


  // var loader = new PageLoader();
  // loader.showLoader();

}
