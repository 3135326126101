
import Header from './header';


$(document).on( 'turbolinks:load',() => {
                                              let header     = new Header();
                                              header.add_dropdown_in_MyAccount();
                                              header.show_hide_header();
                                              header.dropdown();
                                              header.nestedDropdown();
                                              $(window).resize(() => {
                                                                        let header = new Header();
                                                                        header.show_hide_header();
                                                                     })
                                         })

$(document).click(function(event)
{
      let header     = new Header();
      header.closeDropdowns(event);
});