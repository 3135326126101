// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Toastr from 'toastr';
import Jquery from 'jquery';
import "channels";
import nestedForm from '@kanety/jquery-nested-form';
import 'select2';

import 'select2/dist/css/select2.css';
// import 'bootstrap-datepicker';
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import Modal from '../components/modal';
import CustomSelect2 from '../components/customselect2';
import Coupon from '../components/coupon';
// import CustomDatepicker from '../components/customdatepicker';
import AddressDetails from "../pages/addressdetailspage/addressdetails";
import Admin from "../pages/admin_page/admin";
import Checkout from '../pages/checkoutpage/checkout';
import CustomizeStripe from '../common/stripe/customizestripe';
import Dashboard from "../pages/dashboard_page/dashboard";
import RecaptchaValidation from  "../common/recaptcha_validation";
import Cleave from 'cleave.js';
import Splide from '@splidejs/splide';
import Loader from '../components/loader';
import Footer from '../layouts/footer/footer';


global.$               = jQuery;
global.jQuery          = jQuery;
global.AddressDetails  = AddressDetails;
global.CustomizeStripe = CustomizeStripe;
global.CustomSelect2   = CustomSelect2;
global.Toastr          = Toastr;
global.Coupon          = Coupon;
global.Checkout        = Checkout;
global.Dashboard       = Dashboard;
global.Admin           = Admin;
global.Dashboard       = Dashboard;
global.Admin_Table     = '';
global.Footer          = Footer;
global.imNotARobot     = function(){new RecaptchaValidation().imNotARobot();}
global.submitInvisibleRecaptchaForm  = function(){new RecaptchaValidation().submitInvisibleRecaptchaForm();}

require('jquery-validation');
require("components/dropdown");
require("common/formvalidation");
require("common/password");
require("components/modal");
require("components/coupon");

require("pages/homepage/initialize");
require("pages/aboutus/initialize");
require("pages/choosetemplate/initialize");
require("pages/signuppage/initialize");
require("pages/loginpage/initialize");
require("pages/forgotpasswordpage/initialize");
require("pages/resetpasswordpage/initialize");
require("pages/contactuspage/initialize");
require("pages/productpage/initialize");
require("pages/checkoutpage/initialize");
require("pages/changepasswordpage/initialize");
require("pages/settingspage/initialize");
require("pages/orderstatus/initialize");
require("pages/guest_book_entry_page/initialize");
require("pages/guest_gallery_page/initialize");
require("pages/dashboard_page/initialize");
require("pages/reorder_page/initialize");
require("pages/get_email_address_page/initialize");
require("pages/customize_tent_card_page/initialize");
require("pages/customize_book_page/initialize");
require("pages/create_book_page/initialize");
require("pages/preview_page/initialize");
require("pages/admin_page/initialize");
require("pages/myorders/initialize");
require("layouts/header/initialize");
require("layouts/footer/initialize");


Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("pages/addressdetailspage/initialize");
require("pages/paymentpage/initialize");

$(document).on('turbolinks:load',()=>
{

  $('.Modal__CloseIcon').click(function()
  {
      new Modal().closeModal();
      $(document).find('.ConfirmationModal').css('display', 'none');
  })

  // new Datepicker()
});


window.Rails = Rails;
