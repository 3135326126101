class StripeStyle
{
  Theme1 = {
                  base: {
                            fontSize: '16px',
                            color: '#383838',
                            // lineHeight: '19px',

                            '::placeholder':{
                                              color: '#A0A5AB',
                                              fontSize: '14px'
                                            }
                        },
                        'invalid': {
                                      'color': 'red',
                                   }
                }

}

export default StripeStyle;
