import AboutUs from './aboutus';

$(document).on('turbolinks:load',()=>
{
    var aboutus = new AboutUs();

    $('.AboutusPage__Button').click(function()
    {
        aboutus.scroll_to_header();
    })
})
