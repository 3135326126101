import StyleStripe from './stylestripe';

import ErrorsStripe from './errorsstripe';


import FormField from '../formfield';
import Loader from '../../components/loader';
class CustomizeStripe {

  constructor() {
    this.stripestyle = new StyleStripe();
    this.cardNumberElement = '';
    this.form = document.getElementById('payment-form');
    this.user = '';
  }

  stripeInitialize()
  {
    var public_key = document.querySelector("meta[name='stripe-public-key']").content;
    this.stripe = Stripe(public_key);
    this.elements = this.stripe.elements();
  }

  createCardNumber()
  {
    var CustomizeStripeThis = this;

    var cardNumberElement = this.elements.create('cardNumber', {
      style: this.stripestyle.Theme1,
      placeholder: '4242 4242 4242 4242'
    });

    cardNumberElement.mount('#card-number');

    this.cardNumberElement = cardNumberElement;

    cardNumberElement.addEventListener('change', function(event)
    {
      if (event.brand)
      {
        CustomizeStripeThis.setBrandIcon(event.brand);
      }
      var displayError = document.getElementById('card-number-error');
      if (event.error)
      {
        displayError.textContent = new ErrorsStripe().getError(event.error);
        // loader.hide();
      }
      else
      {
        displayError.textContent = '';
      }
    });

  }

  createCardExpiry() {
    var cardExpiryElement = this.elements.create('cardExpiry', {
      style: this.stripestyle.Theme1,
      placeholder: 'MM/YY'
    });
    cardExpiryElement.mount('#card-expiry');

    cardExpiryElement.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-expiry-error');
    if (event.error)
    {
      displayError.textContent = new ErrorsStripe().getError(event.error);
      // loader.hide();
    } else {
      displayError.textContent = '';
    }
  });
  }

  createCardCVC() {
    var cardCvcElement = this.elements.create('cardCvc', {
      style: this.stripestyle.Theme1,
      placeholder: '•••'
    });
    cardCvcElement.mount('#card-cvc');

    cardCvcElement.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-cvc-error');
    if (event.error) {
      displayError.textContent = new ErrorsStripe().getError(event.error);
      // loader.hide();
    } else {
      displayError.textContent = '';
    }
  });
  }

  customizeOnSubmit(type) {
    var customize_stripe = this;
    var type = type;
    $('.PaymentDetails__Submit').on('click', function(event) {
      event.preventDefault();
      $('.PaymentDetails__Submit').prop('disabled', true);
      switch (type) {
        case "Card":
          customize_stripe.createCard(customize_stripe);
          break;
        case "Checkout":
          customize_stripe.createPayment(customize_stripe);
          break;
      }
    });
  }

  createCard(service) {
    service.user = document.getElementById('stripe_user_name').value;
    // service.stripe.handleCardSetup(
    //   service.clientSecret, service.cardNumberElement, {}
    // )
    service.stripe.createPaymentMethod('card', service.cardNumberElement, {
          billing_details: {name: service.user}
        })
    .then(function(result) {
      if (result.error) {
        // var errorElement = document.getElementById('card-errors');
        service.HandleStripeError(service, result);
        // loader.hide();
      } else {
        // loader.show();
        service.stripeCardSubmit(service.form, result);
      }
    });
  }

  stripeCardSubmit(form, result) {
    var values = {
      "setupIntent[payment_method]": result.paymentMethod.id
    };
    var keys = Object.keys(values);
    keys.forEach(function(field) {
      new FormField().Add(form, values, field);
    });
    form.submit();
  }



  createPayment(service) {
    service.user = document.getElementById('stripe_user_name').value;
    service.stripe.createPaymentMethod('card', service.cardNumberElement, {
      billing_details: {
        name: service.user
      }
    }).then(function(result) {
      if (result.error) {
        service.HandleStripeError(service, result);
        // Show error in payment form
      } else {
        service.stripePaymentSubmit(service, result);
      }
    });
  }

  stripePaymentSubmit(service, result) {
    var address_id = $('input[name="address_id"]').val();
    var delivery_partner_id = $('input[name="delivery_partner_id"]:checked').val();
    var product_id = $('input[name="product_id"]').val();
    var coupon_id = $('input[name="coupon_id"]').val();
    var quantity = $('input[name="quantity"]').val();

    var values = {
      "setupIntent[payment_method]": result.paymentMethod.id,
      "address_id": address_id,
      "delivery_partner_id": delivery_partner_id,
      "coupon_id": coupon_id,
      "quantity": quantity,
      "product_id": product_id
    };
    var keys = Object.keys(values);
    keys.forEach(function(field) {
      new FormField().Add(service.form, values, field);
    });
    var data = $(service.form).serialize();
    new Loader().show('Payment');
    $.ajax({
      beforeSend: function(xhr) {
        service.xCSRFToken(xhr)
      },
      url: '/verify-order',
      method: "POST",
      data: data
    });
  }

  xCSRFToken(xhr) {
    return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]')
      .attr('content'))
  }


  HandleStripeError(service, result)
  {
    if ($('#card-number-error, #card-expiry-error, #card-cvc-error').text().length == 0 && result.error.type != "validation_error") {
      Toastr.error(result.error.message);
      // $('#PaymentMethodModal').modal('hide');
      service = new CustomizeStripe();
      service.stripeInitialize();
      new Loader().hide();
    } else {
      if (result.error.code.includes('number')) {
        var displayError = document.getElementById('card-number-error');
      } else if (result.error.code.includes('expiry')) {
        var displayError = document.getElementById('card-expiry-error');
      } else if (result.error.code.includes('cvc')) {
        var displayError = document.getElementById('card-cvc-error');
      } else {
        Toastr().error(result.error.message);
        $('#PaymentMethodModal').modal('hide');
      }
      displayError.textContent = new ErrorsStripe().getError(result.error)
      $(displayError).show();
    }
    $('.PaymentDetails__Submit').removeAttr('disabled');
  }


  VerifyPayment(service, params, intent) {
    params = JSON.parse(params);
    ['controller', 'action', 'setupIntent', 'authenticity_token'].forEach(e => delete params[e]);
    intent = JSON.parse(intent);
    params['intent_id'] = intent.id;
    if (intent.status == 'requires_action' && intent.next_action.type == 'use_stripe_sdk'){
      new Loader().hide();
      service.stripe.handleCardAction(
        intent.client_secret
      ).then(function(result) {
        if (result.error){
          params["message"] = result.error.message
          $.ajax({
            method: "POST",
            beforeSend: function(xhr) {
              service.xCSRFToken(xhr)
            },
            url: '/order-failed',
            data: params
          });
          // new Toastr().error_message(result.error.message);
        }
        else{
          new Loader().show('Payment');
          $.ajax({
            beforeSend: function(xhr) {
              service.xCSRFToken(xhr)
            },
            url: '/orders',
            method: "POST",
            data: params
          });
        }
      });
    }
    else{
      $.ajax({
          beforeSend: function(xhr) {
            service.xCSRFToken(xhr)
          },
          url: '/orders',
          method: "POST",
          data: params
        });
    }
    // service.stripe.confirmCardPayment(intent.client_secret).then(function(result) {
    //   if (result.error) {
    //     params["message"] = result.error.message
    //     $.ajax({
    //       method: "POST",
    //       beforeSend: function(xhr) {
    //         service.xCSRFToken(xhr)
    //       },
    //       url: '/order-failed',
    //       data: params
    //     });
    //     // new Toastr().error_message(result.error.message);
    //   } else {
    //     $.ajax({
    //       beforeSend: function(xhr) {
    //         service.xCSRFToken(xhr)
    //       },
    //       url: '/orders',
    //       method: "POST",
    //       data: params
    //     });
    //     // new Loader().show();
    //   }
    // });
  }

  direct_card_payment() {
    $('.PaymentPage__Pay').on('click', function(e) {
      $('.PaymentPage__Pay').prop('disabled', true);
      e.preventDefault();
      var address_id = $('input[name="address_id"]').val();
      var delivery_partner_id = $('input[name="delivery_partner_id"]:checked').val();
      var product_id = $('input[name="product_id"]').val();
      var coupon_id = $('input[name="coupon_id"]').val();
      var quantity = $('input[name="quantity"]').val();
      var payment_method_id = $('input[name="payment_method_id"]:checked').val();
      var form = document.getElementById('new_order');
      var values = {
        "payment_method_id": payment_method_id,
        "address_id": address_id,
        "delivery_partner_id": delivery_partner_id,
        "coupon_id": coupon_id,
        "quantity": quantity,
        "product_id": product_id
      };
      var keys = Object.keys(values);
      keys.forEach(function(field) {
        new FormField().Add(form, values, field);
      });
      new Loader().show('Payment');
      Rails.fire(form, 'submit');
    })
  }

  disable_payment_button(){
    $('.PaymentPage__Pay').prop('disabled', true);
  }

  enable_payment_button(){
    $('.PaymentPage__Pay').removeAttr('disabled');
  }

  setBrandIcon(brand)
  {
    var cardBrandToPfClass = {
                                'visa': 'fa-cc-visa',
                                'mastercard': 'fa-cc-mastercard',
                                'amex': 'fa-cc-amex',
                                'discover': 'fa-cc-discover',
                                'diners': 'fa-cc-diners-club',
                                'jcb': 'fa-cc-jcb',
                                'unknown': 'fa-cc-credit-card'
                              }

    var brandIconElement = document.getElementById('brand-icon');
    var pfClass = 'fa-cc-credit-card';
    if (brand in cardBrandToPfClass)
    {
      pfClass = cardBrandToPfClass[brand];
    }

    for (var i = brandIconElement.classList.length - 1; i >= 0; i--)
    {
      brandIconElement.classList.remove(brandIconElement.classList[i]);
    }

    brandIconElement.classList.add('fab');

    brandIconElement.classList.add('fs-25');
    brandIconElement.classList.add('color-blue');
    brandIconElement.classList.add(pfClass);
  }


}


export default CustomizeStripe;
