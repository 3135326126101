import FormValidation from '../../common/formvalidation';
import StepProgress from '../../components/stepprogress';
class Checkout
{

  delivery_form_validation()
  {
    var form_class           = ".DeliveryDetails__Form";
    var required_form_fields = ['address[name]','address[contact_no]','address[address_line_1]','address[address_line_2]','address[country]','address[city]','address[state]','address[zipcode]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate('js',false);
  }

  deliver_here(element)
  {
    $('input[name="address_id"]').val($('input[name="address"]:checked').val());
    $('.CheckoutPage__AddressItem').hide();                         // hide all address
    var parent = $(element).parents('.CheckoutPage__AddressItem');  // find the selected parent
    parent.show();                                                  // show the current element
    parent.find('.Radiobutton__Customise').hide();                  // hide the radio button
    parent.find('.CheckoutPage__Address').css('padding-left','0px');
    $(element).hide();                                              // hide the delivere here button
    parent.find('.CheckoutPage__Change').show();                    // show the edit button

    $.ajax({
      url: "/products/"+$('#product_slug').val()+"/get_shipping_methods",
      method: "GET",
      data: { address_id: $('input[name="address"]:checked').val() }
    })

    $('.PaymentDetails__Form').css('display','grid');               // show the payment section form
    $('.CheckoutPage__CardListSection').css('display','block');     // show the payment card available list
    $('.AddressPage__AddNewAddressSection').css('display','none');
    $('.PaymentPage__CardSection').first().find('.Radiobutton__Button').trigger('click');
    var coupon_id = $('#coupon_id').val();
    var quantity  = $('#quantity').val();
    var delivery_partner_id = $('input[name="delivery_partner_id"]:checked').val();
    var data_hash = {
        address_id: $('input[name="address"]:checked').val(),
        coupon_id: coupon_id,
        quantity: quantity,
        delivery_partner_id: delivery_partner_id
    }

    $.ajax({
      url: "/products/"+$('#product_slug').val()+"/deliver_here",
      method: "POST",
      data: data_hash
    });
  }

  change(element)
  {
    var parent = $(element).parents('.CheckoutPage__AddressItem');      // find the selected parent
    $('.CheckoutPage__AddressItem').show();                             // show all address
    $('.CheckoutPage__AddressSection .Radiobutton__Customise').show();  // show the radio button
    $('.CheckoutPage__AddressSection .CheckoutPage__Change').hide();    // hide the edit button
    $('.CheckoutPage__MobileAddressSection .Radiobutton__Customise').show();  // show the radio button
    $('.CheckoutPage__MobileAddressSection .CheckoutPage__Change').hide();    // hide the edit button
    parent.find('.CheckoutPage__Address').css('padding-left','30px');
    parent.find('.CheckoutPage__DeliverHere').show();                   // hide the delivere here button
    $('.PaymentDetails__Form').css('display','none');                   // hide the payment section form if no card available
    $('.CheckoutPage__CardListSection').css('display','none');          // hide the payment card available list
    $('.AddressPage__AddNewAddressSection').css('display','block');
  }

  initialize_stripe()
  {
    var url = window.location.href;
    if(url.includes('/checkout') && url.includes('/products'))
    {
        let customize_stripe = new CustomizeStripe();
        customize_stripe.stripeInitialize();
        if($('#payment-form').length != 0)
        {
          customize_stripe.createCardNumber();
          customize_stripe.createCardCVC();
          customize_stripe.createCardExpiry();
          customize_stripe.customizeOnSubmit('Checkout');
        }
        else if($('.CheckoutPage__AddressSection, .CheckoutPage__MobileAddressSection').length == 0)
        {
          $('.CheckoutPage__CardListSection').css('display','block');
          if($('.CheckoutPage__PaymentSection, .CheckoutPage__MobilePaymentSection').is(':visible')){
            $('.PaymentPage__CardSection').first().find('.Radiobutton__Button').trigger('click');
            $('.PaymentPage__Pay').hide();
            $('.PaymentPage__CardSection').first().find('.PaymentPage__Pay').show();
          }
          customize_stripe.direct_card_payment();
        }
        else if($('.CheckoutPage__PaymentSection, .CheckoutPage__MobilePaymentSection').is(':visible')){
          customize_stripe.direct_card_payment();
        }

    }
  }

  increase_quantity()
  {
    var quantity = $('#book_quantity').val();
    if(quantity > 0)
    {
      quantity = parseInt(quantity) + 1;
      $('#book_quantity').val(quantity);
      $('#book_quantity').trigger("change");
    }
  }

  decrease_quantity()
  {
    var quantity = $('#book_quantity').val();
    if(quantity > 1)
    {
      quantity = parseInt(quantity) - 1;
      $('#book_quantity').val(quantity);
      $('#book_quantity').trigger("change");
    }
  }

  decrease_quantity_click()
  {
    var k_this = this;
    $('.quantity .minus').click(function()
    {
      k_this.decrease_quantity();
    })
  }

  increase_quantity_click()
  {
    var k_this = this;
    $('.quantity .plus').click(function(){
      k_this.increase_quantity();
    })
  }

  quantity_change_event()
  {
    var k_this = this;
    $('#book_quantity').change(function(){
        k_this.quantity_change(this);
    })
  }

  quantity_change(element)
  {
    var quantity     = $(element).val();
    var product_slug = $('#product_slug').val();
    var coupon_code  = $('#coupon_id').val();
    var address_id  = $('#address_id').val();
    // if( $('.CouponStatusSection').is(':visible'))
    // {
    //     coupon_code = $('#coupon_code').val()
    // }

    $.ajax({
      url:'/products/'+product_slug+'/update_book_quantity',
      method:'POST',
      data: {
                quantity: quantity,
                coupon_code: coupon_code,
                address_id: address_id
            }
    })
  }

  set_max_value()
  {
    var k_this = this;
    $('#book_quantity').keyup(function(){
      if(this.value != "")
      {
        if(parseInt(this.value) < parseInt(this.min))
        {
          this.value = this.min;

        }
        if(parseInt(this.value) > parseInt(this.max))
        {
          this.value = this.max;

        }
      }
      k_this.quantity_change(this);
    })
  }

  select_first_address_by_default()
  {
        $('.CheckoutPage__AddressItem').first().find('.Radiobutton__Button').trigger('click');
    // Select the first element by default
        if($('.CheckoutPage__AddressItem').length == 1)
        {
          $('.CheckoutPage__AddressItem').first().find('.CheckoutPage__DeliverHere').trigger('click');
        }


    // Radio Button Click
  }
  apply_coupon_form()
  {
    $('.Coupon__Form__Button').click(function(e){

      var form  = $('.OrderSummaryPage__CouponForm');
      if (form.valid()){
        var address_id = $('#address_id').val();
        var quantity  = $('#quantity').val();
        var data_hash = {
          coupon_code: $('input[name="coupon_code"]').val(),
          address_id: address_id,
          quantity: quantity
        }
        $.ajax({
          url: "/products/"+$('#product_slug').val()+"/apply-coupon",
          method: "POST",
          data: data_hash
        });
        e.preventdefault();
      }
      else{
        form.valid();
      }
    });
  }

  remove_coupon_form()
  {
    $('.Remove_Coupon').click(function(e){

        var address_id = $('#address_id').val();
        var quantity  = $('#quantity').val();
        var data_hash = {
          address_id: address_id,
          quantity: quantity
        }
        $.ajax({
          url: "/products/"+$('#product_slug').val()+"/remove-coupon",
          method: "POST",
          data: data_hash
        });
        e.preventdefault();
    });

  }
}

export default Checkout;
