import Dashboard from './dashboard';
import CustomPlyr from '../../components/customplyr';
import ClipboardJS from 'clipboard/dist/clipboard.min.js';

const dashboard = new Dashboard();
const custom_plyr = new CustomPlyr();

$(document).on('turbolinks:load',()=>
{


    var url = window.location.href;
    var min_length = 10;
    var max_length = 32;
    var all_cards_count = $('.Dashboard__Card').length;
    var elements = $('.Dashboard__Card .guest_pages');
    var button_element = $('.Dashboard__Select-All-Button');

    if( url.includes('/products') && url.includes('/new-book') )
    {
      dashboard.initialize_slide();
      dashboard.splide_on_click_event();
      dashboard.add_form_attr_submit_btn();
      dashboard.change_select_all_button_text(button_element, all_cards_count);
      if( dashboard.is_10_checkbox_selected(min_length, max_length))
      {
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').attr('disabled', false);
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('opacity',1);
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('border', ' 1px solid #FD856A');
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('color', '#FD856A');

      }
      else
      {
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').attr('disabled',true);
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('opacity',1);
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('border', ' 1px solid rgba(253, 133, 106, 0.5)');
        $('.Dashboard__Organiser-Shareable-Info-Build-My-Book').css('color', 'rgba(253, 133, 106, 0.5)');
      }
    }

    // if($('.Dashboard__FormWithGuestEntry').find('.Dashboard__Card').length < min_length)
    // {
    //   $('.Dashboard__SelecteMessage').css('display','block');
    // }
    // else
    // {
    //   $('.Dashboard__SelecteMessage').css('display','none');
    // }

    var clipboard = new ClipboardJS('.Dashboard__Organiser-Shareable-Info-Link');

    clipboard.on('success', function(e)
    {
      // $('.Dashboard__Organiser-Shareable-Info-Link').focus();
      // return false;
    });

    $('.Dashboard-Card__PlayBtn').click(function() {
        var video_src = $(this).parent().find('#guest_video_url').val();
        custom_plyr.set_video_source(video_src);
        custom_plyr.initialize();
        custom_plyr.show_video_modal();
      })

    $('.Video__Modal .Modal__CloseIcon').click(function()
      {
          custom_plyr.destroy_video_modal();
          custom_plyr.hide_video_modal();
      })

    $('.guest_pages').change(function()
    {
        if( dashboard.is_10_checkbox_selected(min_length, max_length))
        {
          dashboard.enable_build_my_book_button();
          $('#max-entries-container').html('');
        }
        else
        {
          dashboard.disabled_build_my_book_button();
          if ($('.guest_pages:checked').length > max_length) {
            $('#max-entries-container').html('<span id="max-entries">Max. <span class="Dashboard__SelectMessage-Highlight">32 entries</span> allowed</span>')
          }
        }

        dashboard.change_select_all_button_text(button_element, all_cards_count)
        dashboard.update_selected_entries($('.Dashboard__Card .guest_pages:checked').length)
    })

    $('.Dashboard__Select-All-Button').click(function()
    {
        if(dashboard.is_all_checkbox_selected(all_cards_count))
        {
          dashboard.change_button_text(this,'Select All');
          dashboard.unselect_all_checkbox(elements);
          if( ! dashboard.is_10_checkbox_selected(min_length, max_length))
          {
            dashboard.disabled_build_my_book_button();
          }
        }
        else
        {
          dashboard.change_button_text(this,'Unselect All');
          dashboard.select_all_checkbox(elements);
          if( dashboard.is_10_checkbox_selected(min_length, max_length))
          {
            dashboard.enable_build_my_book_button();
          }
        }
        dashboard.update_selected_entries($('.Dashboard__Card .guest_pages:checked').length)

        if ($('.guest_pages:checked').length > max_length) {
          $('#max-entries-container').html('<span id="max-entries">Max. <span class="Dashboard__SelectMessage-Highlight">32 entries</span> allowed</span>')
        } else {
          $('#max-entries-container').html('');
        }
    })
      // dashboard.set_masonary_height();
})

// $(document).ready(function()
// {
//     // dashboard.set_masonary_height();
// });
