import FormValidation from '../../common/formvalidation';

class OrderStatus
{

  form_validation()
  {
    var form_class           = ".OrderSummaryPage__CouponForm";
    var required_form_fields = ['coupon_code'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate("js");
  }

  apply_coupon_form()
  {
    $('.Coupon__Form__Button').click(function(e){

      var form  = $('.OrderSummaryPage__CouponForm');
      if (form.valid()){
        var data_hash = {
          coupon_code: $('input[name="coupon_code"]').val(),
        }
        $.ajax({
          url: "/products/"+$('#product_slug').val()+"/apply-coupon",
          method: "POST",
          data: data_hash
        });
        e.preventdefault();
      }
      else{
        form.valid();
      }
    });
  }

  remove_coupon_form()
  {
    $('.Remove_Coupon').click(function(e){
      e.preventdefault();
        $.ajax({
          url: "/products/"+$('#product_slug').val()+"/remove-coupon",
          method: "POST"
        });
    });

  }

}

export default OrderStatus;
