class AboutUs
{

    scroll_to_header()
    {
      $('html, body').animate({
                                scrollTop: $(".AboutusPage__Header").offset().top
                             });
    }


}

export default AboutUs;
