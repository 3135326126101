class CustomSelect2
{
  initialize_select2()
  {
    $('select').select2({
                             minimumResultsForSearch: -1,
                       });

  }

  initialize_search_select2()
  {
    $('.countries_list').select2({
                              closeOnSelect: true,
                              width: '100%'
                        });

  }

}

export default CustomSelect2;
