import FormValidation from '../../common/formvalidation';

class Settings
{

  form_validation()
  {
    var form_class           = ".SettingPage__Form";
    var required_form_fields = ['user[name]','user[email]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }


}

export default Settings;
