class FormField
{
  Add(form, values, field){
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', field);
    hiddenInput.setAttribute('value', values[field]);
    form.appendChild(hiddenInput);
  }
}


export default FormField;