import Payment from './payment';

$(document).on('turbolinks:load',()=>
{
    // ----------  On Confirm Deletion Click Show Modal -----------
        $('.PaymentPage__Remove').click(function(){
            $('.ConfirmationModal').css('display','block');
            var card_id = $(this).attr('data-card-id');
            $('.ConfirmationModal .ConfirmationModal__Accept').attr('href','/payment_methods/'+card_id);
            
        })
    // ------------------------------------------------------------

})
