import FormValidation from '../../common/formvalidation';

class Footer
{
    subscribing_form_validation()
    {
        var form_class           = ".Footer__Subscribing-Form";
        var required_form_fields = ['email'];
        var form_validation      = new FormValidation(form_class);
        form_validation.required_field_validation(required_form_fields);
        form_validation.g_recaptcha_formvalidate();
    }
}

export default Footer;
