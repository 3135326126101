import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';

class SignUp
{

  form_validation()
  {
    var form_class           = ".SignupPage__Form";
    var required_form_fields = ['user[name]','user[email]','user[password]','user[password_confirmation]'];
    var match_form_fields    = [{'original_field_id': '#user_password', 'field_to_be_matched_name':'user[password_confirmation]'}];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.fields_matches_validations(match_form_fields);
    form_validation.formvalidate();
  }

  password_icon_click()
  {
    var password = new PasswordField();
    $('.SignupPage__Form .Form__PasswordIcon').click(function(){
                                                  if( $(this).hasClass('fa-eye-slash') )
                                                  {
                                                      password.show_password(this);
                                                  }
                                                  else if($(this).hasClass('fa-eye'))
                                                  {
                                                      password.hide_password(this);
                                                  }
                                               })
  }

  // show_apple_popup()
  // {
  //   $(".SignupPage__LoginWithApple").click(function()
  //     {
  //       alert("hello");
  //         $("#appleid-signin").click();
  //     });
  // }

  // apple_script(){
  //   $.getScript( "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" ).done(function( script, textStatus ) {
  //     console.log( textStatus );
  //     AppleID.auth.init({
  //               clientId : $('meta[name=client_id]').attr('content'),
  //               scope : 'name email',
  //               redirectURI : $('meta[name=redirect_uri]').attr('content'),
  //               state : $('meta[name=state]').attr('content'),
  //               usePopup : true //or false defaults to false
  //           });
  //   })
  //   .fail(function( jqxhr, settings, exception ) {
  //     $( "div.log" ).text( "Triggered ajaxError handler." );
  //   });
  // }


}

export default SignUp;
