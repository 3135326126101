class Reorder
{

  set_order(current_element)
  {
    var old_element_index = current_element.oldIndex - 2;
    var new_element_index = current_element.newIndex - 2;
    $(current_element.item).find('.Reorder__Order-Number input').val(String(new_element_index));
    var elements = $(current_element.item).parent().find('.fields');

    if(old_element_index > new_element_index)
    {
      for(let index = new_element_index; index < old_element_index; index ++ )
      {
          var element = $(elements[index]).find('.Reorder__Order-Number input');
          var element_index = parseInt( element.val() );
          element.val( element_index + 1 );
      }
    }
    else if(old_element_index < new_element_index)
    {
      for(let index = old_element_index-1; index < new_element_index-1; index ++ )
      {
          var element = $(elements[index]).find('.Reorder__Order-Number input');
          var element_index = parseInt( element.val() );
          element.val( element_index - 1 );
      }
    }

  }

  add_form_attr_submit_btn()
  {
      var form_id =  $('.Reorder__Form').attr('id');
      $('.Reorder__Next').attr('form',form_id);
  }

  set_masonary_height()
  {
      $('.Reorder__Form .fields').each(function()
      {
          var window_width    = window.screen.width;
          var viewport_height = (100/1440*26);
          var px_height       = (viewport_height*1440/100);
          var height          = parseInt(this.scrollHeight/px_height) + 1;
          $(this).css('grid-row', 'span'+' '+height);
      })
  }
}

export default Reorder;
