import HomePage from './homepage';
import Plyr from 'plyr';

$(document).on('turbolinks:load',()=>
{
    var home = new HomePage();
    const customplyr = new Plyr('#home_player', {
                                            ratio: '16:9',
                                            hideControls: true,
                                            controls: false
                                     });

    if (window.location.pathname == '/')
    {
      home.initialize_splide();
    }
    $('.HomePage__Section1-Button').click(function()
    {
        home.scroll_to_section3();
    })

    $('.HomePage__Section3-Btn, .HomePage__Section6-Btn').click(function()
    {
        home.scroll_to_section8();
    })

    $('.HomePage__Section4-PlayBtn').click(function()
    {
      $('.HomePage__Section4-video').css('opacity','1');
      customplyr.play();
      $('.HomePage__Section4-video').css('transition','opacity 0.75s');
      $('.HomePage__Section4-PlayBtnSection').css('display','none');
      $('.plyr__video-wrapper.plyr__video-wrapper--fixed-ratio').css('padding-bottom','0px');
      $('.plyr__video-wrapper.plyr__video-wrapper--fixed-ratio').css('height','100%');
    })


    customplyr.on('ended', event =>
    {
      customplyr.stop();
      $('.HomePage__Section4-video').css('opacity','0');
      $('.HomePage__Section4-video').css('transition','opacity 0.75s');
      $('.HomePage__Section4-PlayBtnSection').css('display','block');

    });
})
