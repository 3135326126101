import SignUp from './signup';

$(document).on('turbolinks:load',()=>
{
                                          var sign_up = new SignUp();
                                          sign_up.form_validation();
                                          sign_up.password_icon_click();
                                          // sign_up.show_apple_popup();
                                          // sign_up.apple_script();
})
