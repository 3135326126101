import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';
import StepProgress from '../../components/stepprogress';
import SelectThemeHeader from '../../components/select_theme_header';
import Cropper from 'cropperjs';
import Splide from '@splidejs/splide';


class Product {

  set_step() {
    var stepProgress = new StepProgress(url);
    var url = window.location.href;
    var element = $('.StepProgress__Section');

    if (url.includes('/products') && url.includes('/new')) {
      stepProgress.add_ready_status(element, 1);
      stepProgress.remove_all_status(element, 1, 4);
    } else if (url.includes('/products') && url.includes('/welcome-details')) {
      stepProgress.add_done_status(element, 1);
      stepProgress.add_ready_status(element, 2);
      stepProgress.remove_all_status(element, 3, 4);
    } else if (url.includes('/products') && url.includes('/select-items') ||
      url.includes('/products') && url.includes('/select-template') ||
      url.includes('/products') && url.includes('/customize-tent-card')
    ) {
      stepProgress.add_done_status(element, 2);
      stepProgress.add_ready_status(element, 3);
      stepProgress.remove_all_status(element, 4);
    } else if (url.includes('/products') && url.includes('/order-summary')) {
      stepProgress.add_done_status(element, 3);
      stepProgress.add_ready_status(element, 4);
    }

  }

  step_1_form_validation() {
    var form_class = ".Product__Form";
    var required_form_fields = ['product[name]', 'product[subject]', 'product[occasion_id]'];
    // var current_date_validation_fields = ['product[event_date]'];
    var form_validation = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    // form_validation.fields_current_date_validations(current_date_validation_fields);
    form_validation.formvalidate();

    // var date = new Date();
    // var current_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    // if ($('#product_event_date').length > 0) {
    //   new Cleave('#product_event_date', {
    //     date: true,
    //     delimiter: '-',
    //     datePattern: ['d', 'm', 'Y'],
    //     dateMin: current_date
    //   })
    // }

  }

  step_2_form_validation() {
    var form_class = ".WelcomeDetailsPage__Form";
    var required_form_fields = ['product[welcome_detail_attributes][title]'];
    var custom_required_form_fields = ['product[welcome_detail_attributes][picture]'];
    var form_validation = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.required_select_file_image(custom_required_form_fields);
    form_validation.formvalidate();
  }

  step_2_initialize_cropping_area() {
    var image = $('.WelcomeDetailsPage__OriginalImage')[0];
    cropper = new Cropper(image, {
      scalable: false,
      viewMode: 3,
      dragMode: 'move',
      autoCropArea: 1,
      restore: false,
      modal: false,
      guides: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      checkCrossOrigin: false,
      toggleDragModeOnDblclick: false,
    });
  }

  step_2_change_crop_icon_to_done_icon() {
    var element = $('.WelcomeDetailsPage__CropIconSection');
    element.css('display', 'none');

    var element = $('.WelcomeDetailsPage__DoneIconSection');
    element.css('display', 'grid');

    // show the reposition image of text
    $('.Reposition_Message').show();
  }

  step_2_crop_image() {
    var result = $('.WelcomeDetailsPage__OriginalImage')[0];
    var image = new Image();
    var canvas = cropper.getCroppedCanvas();
    result.src = cropper.getCroppedCanvas().toDataURL('image/jpeg');
    var cropper_data = cropper.getData();
    $('.WelcomeDetailsPage__Image').css("background-image", "url(" + result.src + ")");
    $('#product_welcome_detail_attributes_crop_values')[0].value = JSON.stringify(cropper_data);
  }

  step_2_change_done_icon_to_crop_icon() {
    var element = $('.WelcomeDetailsPage__CropIconSection');
    element.css('display', 'grid');

    var element = $('.WelcomeDetailsPage__DoneIconSection');
    element.css('display', 'none');

    // show the reposition image of text
    $('.Reposition_Message').hide();
  }

  step_2_destroy_cropper() {
    cropper.destroy();
  }

  step_2_disable_next_button() {
    $('.WelcomeDetailsPage__Submit').prop('disabled', true);
  }

  step_2_enable_next_button() {
    $('.WelcomeDetailsPage__Submit').prop('disabled', false);
  }

  step_2_open_image_file() {
    $('.WelcomeDetailsPage__ImageSection').click(function() {
      $('.WelcomeDetailsPage__Input').trigger('click');
    })

    $('.WelcomeDetailsPage__ShowImageSection').click(function(event) {
      if (!$(this).find('img').hasClass('cropper-hidden') &&
        !$(event.target).hasClass('WelcomeDetailsPage__CropIcon') &&
        !$(event.target).hasClass('WelcomeDetailsPage__CropIconSection') &&
        !$(event.target).hasClass('WelcomeDetailsPage__DoneIcon') &&
        !$(event.target).hasClass('WelcomeDetailsPage__DoneIconSection')
      ) {
        $('.WelcomeDetailsPage__Input').trigger('click');
        $('.WelcomeDetailsPage__Input').change(function() {
          $('#product_welcome_detail_attributes_crop_values')[0].value = '';
        })
      }
    })
  }

  step_2_set_image(image_url) {
    $('.WelcomeDetailsPage__Image').css("background-image", "url(" + image_url + ")");
    $('.WelcomeDetailsPage__OriginalImage').attr('src', image_url);
    $('.WelcomeDetailsPage__ShowImageSection').removeClass('d-none');
    $('.WelcomeDetailsPage__ShowImageSection').css('display', 'flex');
    $('.WelcomeDetailsPage__ImageInput').css('display', 'none');
  }

  step_2_set_original_image_url(url) {
    $('#original_image_url').val(url);
  }

  step_3_submit_quantity_form() {
    $('.SelectQuantityPage__Submit').click(() => {
      $('#QuantityButton').trigger('click');
    })
  }



  step_3_select_template() {

    $('.SelectTemplatePage__Theme').click(function()
    {
      $(this).find('input[type="radio"]').prop("checked", true);
      $('.SelectTemplatePage__ThemeSection').find('.SelectTemplatePage__Theme').removeClass('SelectTemplatePage__SelectedTheme');
      $(this).addClass('SelectTemplatePage__SelectedTheme');
    })

    if( $('.SelectTemplatePage__RadioButton:checked').val() == undefined )
    {
      // $('.SelectTemplatePage__Theme').first().find('input[type="radio"]').click();


      var choosetemplateveryfirst = $('.choosetemplateveryfirst').val();
      // alert(choosetemplateveryfirst);

      $('.SelectTemplatePage__RadioButton').each(function(){
        var value = $(this).val();
        // alert(value);

        if ( choosetemplateveryfirst === value ) {
          $("input[name='product[book_item_attributes][book_detail_attributes][book_template_id]'][value=" + value + "]").attr('checked', 'checked');
          var selected_theme1 = $('.SelectTemplatePage__RadioButton:checked');
          $(selected_theme1).parent('.SelectTemplatePage__Theme').addClass('SelectTemplatePage__SelectedTheme');
        }
      });

    }
    else
    {
      var selected_theme = $('.SelectTemplatePage__RadioButton:checked');
      $(selected_theme).parent('.SelectTemplatePage__Theme').addClass('SelectTemplatePage__SelectedTheme');
    }
  }

  step_3_initialize_splide() {
    $('.SelectTemplatePage__SlideSection').each(function() {
      var splide = new Splide(this, {
        type: 'loop',
        pagination: true,
        arrows: false,
      }).mount();

      // Start autoplay on hover and stop when not hovering
      // Variables to hold interval IDs
      var autoAdvanceInterval;

      // Function to start auto-advance
      function startAutoAdvance() {
          autoAdvanceInterval = setInterval(function() {
              splide.go('>');
          }, 2000); // Adjust this interval as needed
      }

      // Function to stop auto-advance
      function stopAutoAdvance() {
          clearInterval(autoAdvanceInterval);
      }

      // Attach hover event handlers
      $(this).hover(startAutoAdvance, stopAutoAdvance);

      // Move the carousel in the respective directions on clicking left or right sides
      $(this).on('click', function(e) {
        var carouselWidth = $(this).width();
        var clickPosition = e.pageX - $(this).offset().left;

        if (clickPosition < carouselWidth / 2) {
            splide.go('<');
        } else {
            splide.go('>');
        }
      });
    });
  }


  step_4_change_tent_card_amount() {
    $('.Radiobutton__Button').change(function() {
      var actual_amount;
      switch(parseInt($(this).val())) {
        case 20:
          actual_amount = 30.00;
          // code block
          break;
        case 50:
          actual_amount = 70.00;
          // code block
          break;
        case 75:
          actual_amount = 97.50;
          // code block
          break;
        default:
          actual_amount = 0.00;
          // code block
      }
      // var actual_amount = parseInt($(this).val()) * parseInt($('#SelectQuantityPage__Section2-Amount').val());
      var currency_symbol = $('#SelectQuantityPage__Section2-Currency').val();
      if (actual_amount == 0) {
        $('.SelectQuantityPage__Section2-Amount').text("");
      } else {
        $('.SelectQuantityPage__Section2-Amount').text(String(currency_symbol + actual_amount.toFixed(2)));
      }
    })
  }

  step_4_initialize_stripe() {
    var url = window.location.href;
    if (url.includes('/welcome-details') && url.includes('/products')) {
      let customize_stripe = new CustomizeStripe();
      customize_stripe.stripeInitialize();
      customize_stripe.createCardNumber();
      customize_stripe.createCardCVC();
      customize_stripe.createCardExpiry();
    }
  }
}

export default Product;
