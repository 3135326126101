import FormValidation from '../../common/formvalidation';
import PasswordField from '../../common/password';

class LogIn
{

  form_validation()
  {
    var form_class           = ".LoginPage__Form";
    var required_form_fields = ['user[email]','user[password]'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

  password_icon_click()
  {

    var password = new PasswordField();
    $('.LoginPage__Form .Form__PasswordIcon').click(function(){
                                                  if( $(this).hasClass('fa-eye-slash') )
                                                  {
                                                      password.show_password(this);
                                                  }
                                                  else if($(this).hasClass('fa-eye'))
                                                  {
                                                      password.hide_password(this);
                                                  }
                                               })
  }

}

export default LogIn;
