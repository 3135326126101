import CreateBook from './create_book';
import CustomPlyr from '../../components/customplyr';
global.cropper = '';

$(document).on('turbolinks:load',()=>
{
    var fade_ids = [];
    var url = window.location.href;
    var create_book = new CreateBook();
    const custom_plyr = new CustomPlyr();

    $('.CreateBookMediaCard__PlayCircle').click(function() {
        var video_src = $(this).parent().find('#guest_video_url').val();
        custom_plyr.set_video_source(video_src);
        custom_plyr.initialize();
        custom_plyr.show_video_modal();
      })

      $('.Video__Modal .Modal__CloseIcon').click(function()
      {
          custom_plyr.hide_video_modal();
          custom_plyr.destroy_video_modal();
      })
    // create_book.blank_fade_ids();
    create_book.setImageFaders(fade_ids);
    create_book.set_font_sizes();

})
