// import PageLoader from './pageloader';
import InputField from '../../common/inputfieldmax';
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';
import CameraFunctionality from './captureImage';
import Loader from '../../components/loader';

class VideoFunctionality
{

   constructor(camera_object)
   {
      const options = {
                       controls: false,
                       autoplay: false,
                       fluid: true,
                       loop: false,
                       width: 460,
                       height: 460,
                       aspectRatio : "1:1",
                       bigPlayButton: false,
                       controlBar: {
                                       volumePanel: false
                                   },
                       plugins: {
                                   record: {
                                               audio: true,
                                               video: {
                                                   // video media constraints: set resolution of camera
                                                   width: { min: 460, ideal: 460, max: 1200},
                                                   height: { min: 460, ideal: 460, max: 1200 }
                                               },
                                               // videoEngine  ''
                                               // dimensions of captured video frames
                                               frameWidth: 460,
                                               frameHeight: 460,
                                               maxLength: 121,
                                               // videoMimeType: 'video/x-matroska',
                                               debug: true
                                            }
                                }
                   };
      this.loader  = new Loader();

      if($('#VideoPage__Preview').length != 0)
      {
      this.player = videojs('VideoPage__Preview', options, () => {
                         // print version information at startup
                         const msg = 'Using video.js ' + videojs.VERSION +
                                     ' with videojs-record ' + videojs.getPluginVersion('record') +
                                     ' and recordrtc ' + RecordRTC.version;
                         videojs.log(msg);
                      });
      }
      else
      {
        this.player;
      }

      if(camera_object!=null)
      {
        this.camerafunc = camera_object;
      }
   }

    initialize_video_blob()
    {
        this.video_blob;
    }

    async recordingBegins_Step_1(camerafunc)
    {
        var k_this = this;

        this.player.record().getDevice();

        this.player.on('deviceReady', () =>
        {
            // k_this.player.record().start();
        });

         // user clicked the record button and started recording
         this.player.on('startRecord', () => {
            var seconds = 1;
            $('.GuestBookEntryPage__Step3 .CaptureImagePage__CountDown-Text').css('color','#808080');
            setInterval(()=>{
                                    seconds = seconds+1;
                                    $('.GuestBookEntryPage__Step3 .CaptureImagePage__CountDown').text(seconds);
                                    $(".VideoPage__TimeSlider input[type=range]").css('background-image',
                                    '-webkit-gradient(linear, left top, right top, ' +
                                    'color-stop(' + (seconds*5/6) +'%, #FA6800), ' +
                                    'color-stop(' + (seconds*5/6) +'%, #D8D8D8)' +
                                    ')');

                                  $(".VideoPage__TimeSlider input[type=range]").css('background-image',
                                      '-moz-linear-gradient(left center, #DF7164 0%, #DF7164 ' + (seconds*5/6) + '%, #F5D0CC ' + (seconds*5/6) + '%, #F5D0CC 100%)');


                                  $("#timeRange").val(seconds);
                              },1000);
            // $('.GuestBookEntryPage__Step2-1').hide();
            // $('.GuestBookEntryPage__Step3').show();
            // Hide the tittle and show the slider and the stop button
            $('.GuestBookEntryPage__Step3 .CaptureImagePage__LoadingDotsContainerWithMessage').fadeOut();
            $('.GuestBookEntryPage__Step3 .VideoPage__TimeSlider').fadeIn();
            $('.GuestBookEntryPage__Step3 .VideoPage__CloseButtonSection').fadeIn();

         });

         // user completed recording and stream is available
         this.player.on('finishRecord', () => {
             k_this.initialize_video_blob();
             k_this.player.record().stopStream();
             let recordedBlob = k_this.player.recordedData;
             k_this.video_blob = recordedBlob;
             k_this.add_video_and_image(camerafunc);
             this.loader.hide();
             $('.GuestBookEntryPage__Step3').hide();
             $('.GuestBookEntryPage__Step4').show();
             $('.GuestBookEntryPage__Heading').html('Review Your Entry');
             new InputField().maxlength($('.GuestAdditionDetails__Email'),50);
             new InputField().maxlength($('.GuestAdditionDetails__Message'),350);
         });

          // error handler
          this.player.on('error', (element, error) =>
          {
              console.error(error);
              alert("Your browser is not fullfill the requirements");
              // location.reload();
          });

          this.player.on('deviceError', () =>
          {
              console.error(k_this.player.deviceErrorCode);
              alert("User doesn't allow the browser to access the webcam and/or microphone");
              location.reload();
          });
  }

    recordingBegins_StopRecording()
    {
      $('.VideoPage__CloseButton').addClass('active');
      this.player.record().stop();
      this.loader.show('Video');
    }

    add_video_and_image(camerafunc)
    {
      var video_url = window.URL.createObjectURL(this.video_blob);
      var image_url = window.URL.createObjectURL(camerafunc.image_blob);
      // $('.GuestBookEntryPage__VideoSection').html(" <image src='"+image_url+"' class='GuestBookEntryPage__Image'/><i class='fas fa-play-circle GuestBookEntryPage__VideoIcon'></i>");
      $('.GuestBookEntryPage__VideoSection').html("<img class='GuestBookEntryPage__VideoThumbnail' /><i class='fas fa-play-circle GuestBookEntryPage__VideoIcon'></i>");
      // $('.GuestBookEntryPage__VideoSection').append(" <image href='"+this.camerafunc.image_blob+"'/>");
      // $('.GuestBookEntryPage__ImageSection').html(" <image src='"+image_url+"' class='GuestBookEntryPage__Image'/>");
      $('.GuestBookEntryPage__ImageSection').html(" <div class='GuestBookEntryPage__Image'></div>");
      $('.GuestBookEntryPage__Image').css('background', 'url(' + image_url + ')');
      $('#player').find('source').attr('src', video_url);
      $('#player')[0].load();
    }

    start_recording()
    {
      this.loader.show("Custom");
      this.player.record().start();
      this.loader.hide_without_delay();
    }
}

export default VideoFunctionality;