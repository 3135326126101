import FormValidation from '../../common/formvalidation';
import Cropper from 'cropperjs';


class GuestBookEntryPage
{

  form_validation()
  {
    var form_class           = ".GuestBookEntryPage__Form";
    var required_form_fields = ['code'];
    var form_validation      = new FormValidation(form_class);
    form_validation.required_field_validation(required_form_fields);
    form_validation.formvalidate();
  }

  initialize_cropping_area() {
    var image = $('.GuestBookEntryPage__OriginalImage')[0];
    cropper = new Cropper(image, {
      scalable: false,
      viewMode: 3,
      dragMode: 'move',
      autoCropArea: 1,
      restore: false,
      modal: false,
      guides: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      checkCrossOrigin: false,
      toggleDragModeOnDblclick: false,
    });
  }

  change_crop_icon_to_done_icon() {
    var element = $('.GuestBookEntryPage__CropIconSection');
    element.css('display', 'none');

    var element = $('.GuestBookEntryPage__DoneIconSection');
    element.css('display', 'grid');

    // show the reposition image of text
    $('.Reposition_Message').show();
  }

  crop_image() {
    var result = $('.GuestBookEntryPage__OriginalImage')[0];
    var image = new Image();
    var canvas = cropper.getCroppedCanvas();
    result.src = cropper.getCroppedCanvas().toDataURL('image/jpeg');
    var cropper_data = cropper.getData();
    $('.GuestBookEntryPage__ImageB').css("background-image", "url(" + result.src + ")");
    $('#product_guest_crop_values')[0].value = JSON.stringify(cropper_data);
  }

  change_done_icon_to_crop_icon() {
    var element = $('.GuestBookEntryPage__CropIconSection');
    element.css('display', 'grid');

    var element = $('.GuestBookEntryPage__DoneIconSection');
    element.css('display', 'none');

    // show the reposition image of text
    $('.Reposition_Message').hide();
  }

  destroy_cropper() {
    cropper.destroy();
  }

  disable_next_button() {
    $('.GuestBookEntryPage__NextButton').prop('disabled', true);
  }

  enable_next_button() {
    $('.GuestBookEntryPage__NextButton').prop('disabled', false);
  }

  open_image_file() {
    $('.GuestBookEntryPage__ImageUploadSection').click(function() {
      $('.GuestBookEntryPage__Input').trigger('click');
    })

    $('.GuestBookEntryPage__ShowImageSection').click(function(event) {
      if (!$(this).find('img').hasClass('cropper-hidden') &&
        !$(event.target).hasClass('GuestBookEntryPage__CropIcon') &&
        !$(event.target).hasClass('GuestBookEntryPage__CropIconSection') &&
        !$(event.target).hasClass('GuestBookEntryPage__DoneIcon') &&
        !$(event.target).hasClass('GuestBookEntryPage__DoneIconSection')
      ) {
        $('.GuestBookEntryPage__Input').trigger('click');
        $('.GuestBookEntryPage__Input').change(function() {
          $('#product_guest_crop_values')[0].value = '';
        })
      }
    })
  }

  set_image(image_url) {
    $('.GuestBookEntryPage__ImageB').css("background-image", "url(" + image_url + ")");
    $('.GuestBookEntryPage__OriginalImage').attr('src', image_url);
    $('.GuestBookEntryPage__ShowImageSection').removeClass('d-none');
    $('.GuestBookEntryPage__ShowImageSection').css('display', 'flex');
    $('.GuestBookEntryPage__ImageInput').css('display', 'none');
  }

  set_original_image_url(url) {
    $('#original_image_url').val(url);
  }
}

export default GuestBookEntryPage;
