import consumer from "./consumer"
import Toastr from 'toastr';
$(document).on('turbolinks:load', function () {
    if ($("meta[name='current-user']").length > 0) {
consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // console.log('connected to socket.');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // console.log('disconnected from socket.');
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Toastr.success(data.message);
    // $(".notifications").html(data.message);
    // Called when there's incoming data on the websocket for this channel
  }
});
}
});
