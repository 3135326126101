import Sortable from 'sortablejs';
import Reorder from './reorder';

const reorder = new Reorder();

$(document).on('turbolinks:load',()=>
{

  var url = window.location.href;

  if( url.includes('/products') && url.includes('/reorder-pages') )
  {
    var el = $('.Reorder .edit_user_product.Reorder__Form')[0];
    var sortable = Sortable.create(el, { onEnd: reorder.set_order.bind(this)});
  }

  reorder.add_form_attr_submit_btn();
  // reorder.set_masonary_height();
})

// $(document).ready(function()
// {
//     reorder.set_masonary_height();
// });
