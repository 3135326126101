import Admin from './admin';

$(document).on('turbolinks:load', function(){

  var admin = new Admin();

  admin.initialize_admin_table(null);
  admin.filter_form_validation();
  admin.send_admin_filter();
  admin.open_modal();


  $('.Filters__Heading-Section').click(function()
  {
      if($('.Filters__Form').is(':visible'))
      {
        admin.close_the_filter();
        admin.change_to_close_icon()
      }
      else
      {
        admin.open_the_filter();
        admin.change_to_open_icon()
      }
  })

  $('.Filters__Button-Section .Clear').click(function()
  {
        admin.reset_form( $('.Filters__Form'));
        admin.remove_admin_fliter_date_error();
        admin.destroy_datatable();
        admin.initialize_admin_table();

  })

  if ($('#filters_start_date').length > 0) {
    new Cleave('#filters_start_date', {
      date: true,
      delimiter: '-',
      datePattern: ['d', 'm', 'Y'],
    })
  }

  if ($('#filters_end_date').length > 0) {
    new Cleave('#filters_end_date', {
      date: true,
      delimiter: '-',
      datePattern: ['d', 'm', 'Y'],
    })
  }

})
