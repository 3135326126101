import Preview from './preview';

const preview = new Preview();

$(document).on('turbolinks:load',()=>
{
    preview.hide_previous_icon();

    $('.PreviewBook__Next').click(function(){
        preview.next_page();
        preview.set_font_sizes();
    })

    $('.PreviewBook__Previous').click(function(){
        preview.previous_page();
        preview.set_font_sizes();
    })


})

$(document).ready( function()
{
    preview.set_cover_font_sizes();
})
